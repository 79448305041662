import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Paper,
} from "@mui/material";
import { isEmpty } from "lodash";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import moment from "moment";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";
import { getRol, getToken, getUser } from "../../../api/Account";
import NoData from "../../../components/NoData";
import { getCierreCajaAsync } from "../../../api/Report";
import { simpleMessage } from "../../../helpers/Helpers";
import { PrintReport } from "../../../components/PrintReport";
import { getSucursalByUserAsync } from "../../../api/Users";

const CierreCajaDetallado = () => {
  const [data, setData] = useState([]);

  const [sumDesembolsos, setSumDesembolsos] = useState(0);
  const [sumGastos, setSumGastos] = useState(0);
  const [sumAbonosAnulados, setSumAbonosAnulados] = useState(0);

  const [sumRecuperacion, setSumRecuperacion] = useState(0);
  const [totalSalidas, setTotalSalidas] = useState(0);
  const [totalEntradas, setTotalEntradas] = useState(0);
  const [cierre, setCierre] = useState(0);

  const [sucursalName, setSucursalName] = useState("");
  const [sucursalList, setSucursalList] = useState([]);

  const compRef = useRef();
  const { selectedSucursal, fecha } = useParams();
  const { setIsLoading, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const token = getToken();
  const user = getUser();
  const rol = getRol();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let sucursals = await getSucursals();

      if (selectedSucursal === "t") {
        setSucursalName("Todas");
      } else {
        let name = sucursals.filter(
          (item) => item.id.toString() === selectedSucursal
        );
        setSucursalName(name[0].description);
      }

      const result = await getCierreCajaAsync(
        token,
        fecha.toString(),
        selectedSucursal === "t" ? 0 : selectedSucursal
      );
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);

      setData(result.data.result);
      setTotales(result.data.result);
    })();
  }, []);

  const setTotales = (data) => {
    let totalDesembolsos = 0;
    let totalGastos = 0;
    let totalAbonosAnulados = 0;
    let totalRecuperacion = 0;

    data.desembolsos.map((item) => (totalDesembolsos += item.montoEntregado));
    setSumDesembolsos(totalDesembolsos);

    data.gastos.map((item) => (totalGastos += item.entradas));
    setSumGastos(totalGastos);

    data.abonosAnulados.map(
      (item) => (totalAbonosAnulados += item.totalRecibido)
    );
    setSumAbonosAnulados(totalAbonosAnulados);

    //ingresos
    data.recuperacion.map((item) => (totalRecuperacion += item.totalRecibido));
    setSumRecuperacion(totalRecuperacion);

    setTotalSalidas(totalDesembolsos + totalGastos + totalAbonosAnulados);

    setTotalEntradas(totalRecuperacion);

    setCierre(
      totalRecuperacion - (totalDesembolsos + totalGastos + totalAbonosAnulados)
    );
  };

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page {  margin: 8mm; }
`;

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);

    setSucursalList(
      selectedSucursal === "t"
        ? result.data.result
        : result.data.result.filter((g) => g.id.toString() === selectedSucursal)
    );
    return result.data.result;
  };
  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Typography
            sx={{
              color: "#2196f3",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {slogan}
          </Typography>
          <Typography
            sx={{
              color: "#2196f3",
              textAlign: "center",
              fontWeight: "bold",
            }}
            variant="h6"
            component="div"
          >
            Reporte Cierre de Caja Detallado
          </Typography>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  Fecha Seleccionada:
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {moment(fecha).format("L")}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          <ReactToPrint
            trigger={() => {
              return (
                <IconButton
                  variant="outlined"
                  style={{ position: "fixed", right: 20, top: 75 }}
                >
                  <PrintRoundedIcon
                    style={{ fontSize: 35, color: "#2979ff", width: 35 }}
                  />
                </IconButton>
              );
            }}
            content={() => compRef.current}
            pageStyle={pageStyle}
          />
        </Stack>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <hr />

          {isEmpty(data) ? (
            <NoData />
          ) : (
            <>
              <Paper
                elevation={10}
                style={{
                  padding: 20,
                }}
              >
                <Typography
                  sx={{
                    color: "#ffc400",
                    textAlign: "center",
                    marginBottom: 1,
                  }}
                  fontSize={16}
                >
                  Egresos:
                </Typography>
                <Stack spacing={2}>
                  <Paper
                    elevation={10}
                    style={{
                      padding: 20,
                    }}
                  >
                    <Stack direction={"row"} justifyContent="space-between">
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={13}
                      >
                        Desembolsos:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#ffc400",
                          textAlign: "left",
                        }}
                        fontSize={13}
                      >
                        {`Σ= ${new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(sumDesembolsos)}`}
                      </Typography>
                    </Stack>

                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Cliente
                            </TableCell>

                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Fecha de Entrega
                            </TableCell>

                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Monto Entregado
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.desembolsos.map((row) => {
                            return (
                              <TableRow key={row.id}>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.id}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.client.fullName}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {moment(row.fechaEntrega).format("L")}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.montoEntregado)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>

                  <Paper
                    elevation={10}
                    style={{
                      padding: 20,
                    }}
                  >
                    <Stack direction={"row"} justifyContent="space-between">
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={13}
                      >
                        Cuotas Anuladas:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#ffc400",
                          textAlign: "left",
                        }}
                        fontSize={13}
                      >
                        {`Σ= ${new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(sumAbonosAnulados)}`}
                      </Typography>
                    </Stack>
                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Fecha
                            </TableCell>

                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Concepto
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Monto
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.abonosAnulados.map((row) => {
                            return (
                              <TableRow key={row.id}>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.id}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {moment(row.fecha).format("L")}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.concepto}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.totalRecibido)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>

                  <Paper
                    elevation={10}
                    style={{
                      padding: 20,
                    }}
                  >
                    <Stack direction={"row"} justifyContent="space-between">
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={13}
                      >
                        Gastos:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#ffc400",
                          textAlign: "left",
                        }}
                        fontSize={13}
                      >
                        {`Σ= ${new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(sumGastos)}`}
                      </Typography>
                    </Stack>
                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Fecha
                            </TableCell>

                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Descripcion
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Monto
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.gastos.map((row) => {
                            return (
                              <TableRow key={row.id}>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.id}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {moment(row.fecha).format("L")}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.description}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.entradas)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Stack>
              </Paper>

              <hr />

              <Paper
                elevation={10}
                style={{
                  padding: 20,
                }}
              >
                <Typography
                  sx={{
                    color: "#00a152",
                    textAlign: "center",
                    marginBottom: 1,
                  }}
                  fontSize={16}
                >
                  Ingresos:
                </Typography>

                <Stack spacing={2}>
                  <Paper
                    elevation={10}
                    style={{
                      padding: 20,
                    }}
                  >
                    <Stack direction={"row"} justifyContent="space-between">
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={13}
                      >
                        Recuperación:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#00a152",
                          textAlign: "left",
                        }}
                        fontSize={13}
                      >
                        {`Σ= ${new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(sumRecuperacion)}`}
                      </Typography>
                    </Stack>
                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Fecha
                            </TableCell>

                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Concepto
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Monto
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.recuperacion.map((row) => {
                            return (
                              <TableRow key={row.id}>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.id}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {moment(row.fecha).format("L")}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.concepto}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.totalRecibido)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Stack>
              </Paper>

              <hr />

              <Paper
                elevation={10}
                style={{
                  padding: 20,
                }}
              >
                <Typography
                  sx={{
                    color: "#00a152",
                    textAlign: "center",
                    marginBottom: 1,
                  }}
                  fontSize={16}
                >
                  Consolidado:
                </Typography>

                <hr />

                <TableContainer>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                        >
                          Fecha
                        </TableCell>
                        <TableCell
                          align="left"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                        >
                          Descripción
                        </TableCell>

                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                        >
                          Entrada
                        </TableCell>

                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                        >
                          Salida
                        </TableCell>

                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                        >
                          Saldo
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow key={1}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 11 }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: 11 }}>
                          DESEMBOLSOS DEL DIA
                        </TableCell>

                        <TableCell align="center" style={{ fontSize: 11 }}>
                          -
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ fontSize: 11, color: "#ffc400" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumDesembolsos)}
                        </TableCell>

                        <TableCell align="center" style={{ fontSize: 11 }}>
                          -
                        </TableCell>
                      </TableRow>

                      <TableRow key={2}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 11 }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: 11 }}>
                          GASTOS
                        </TableCell>

                        <TableCell align="center" style={{ fontSize: 11 }}>
                          -
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ fontSize: 11, color: "#ffc400" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumGastos)}
                        </TableCell>

                        <TableCell align="center" style={{ fontSize: 11 }}>
                          -
                        </TableCell>
                      </TableRow>

                      <TableRow key={3}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 11 }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: 11 }}>
                          ANULACIONES DE ABONOS
                        </TableCell>

                        <TableCell align="center" style={{ fontSize: 11 }}>
                          -
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ fontSize: 11, color: "#ffc400" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumAbonosAnulados)}
                        </TableCell>

                        <TableCell align="center" style={{ fontSize: 11 }}>
                          -
                        </TableCell>
                      </TableRow>

                      <TableRow key={7}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 11 }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: 11 }}>
                          RECUPERACION DEL DIA
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ fontSize: 11, color: "#00a152" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumRecuperacion)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          -
                        </TableCell>

                        <TableCell align="center" style={{ fontSize: 11 }}>
                          -
                        </TableCell>
                      </TableRow>

                      <TableRow key={10}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 14, fontWeight: "bold" }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ fontSize: 14, fontWeight: "bold" }}
                        >
                          CIERRE DEL DIA
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            fontSize: 14,
                            color: "#00a152",
                            fontWeight: "bold",
                          }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(totalEntradas)}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            fontSize: 14,
                            color: "#ffc400",
                            fontWeight: "bold",
                          }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(totalSalidas)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: 14,
                            color: "#2979ff",
                            fontWeight: "bold",
                          }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(cierre)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <hr />
              </Paper>
            </>
          )}
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte Cierre de Caja Detallado"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Stack direction={"row"} justifyContent="space-between">
            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "left",
                }}
                fontSize={11}
              >
                Parametros:
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {`Sucursal: ${
                  selectedSucursal === "t" ? "Todas" : sucursalName
                }`}
              </Typography>

              <Typography fontSize={11} textAlign="left">
                Fecha Seleccionada:
              </Typography>

              <Typography fontSize={11} textAlign="left">
                {moment(fecha).format("L")}
              </Typography>
            </Stack>

            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "left",
                }}
                fontSize={11}
              >
                Usuario:
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {rol}
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {user}
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
              </Typography>
            </Stack>
          </Stack>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <hr />

            {isEmpty(data) ? (
              <NoData />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#2979ff",
                    textAlign: "center",
                  }}
                  fontSize={16}
                >
                  Egresos:
                </Typography>
                <div>
                  <div>
                    <Stack direction={"row"} justifyContent="space-between">
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={13}
                      >
                        Desembolsos:
                      </Typography>
                      <Typography
                        className="text-dark"
                        sx={{
                          textAlign: "left",
                        }}
                        fontSize={13}
                      >
                        {`Σ= ${new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(sumDesembolsos)}`}
                      </Typography>
                    </Stack>
                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Cliente
                            </TableCell>

                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Fecha de Entrega
                            </TableCell>

                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Monto Entregado
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.desembolsos.map((row) => {
                            return (
                              <TableRow key={row.id}>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {row.id}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {row.client.fullName}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {moment(row.fechaEntrega).format("L")}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.montoEntregado)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>

                  <hr />

                  <div>
                    <Stack direction={"row"} justifyContent="space-between">
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={13}
                      >
                        Cuotas Anuladas:
                      </Typography>
                      <Typography
                        className="text-dark"
                        sx={{
                          textAlign: "left",
                        }}
                        fontSize={13}
                      >
                        {`Σ= ${new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(sumAbonosAnulados)}`}
                      </Typography>
                    </Stack>
                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Fecha
                            </TableCell>

                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Concepto
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Monto
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.abonosAnulados.map((row) => {
                            return (
                              <TableRow key={row.id}>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {row.id}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {moment(row.fecha).format("L")}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {row.concepto}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.totalRecibido)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>

                  <hr />

                  <div>
                    <Stack direction={"row"} justifyContent="space-between">
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={13}
                      >
                        Gastos :
                      </Typography>
                      <Typography
                        className="text-dark"
                        sx={{
                          textAlign: "left",
                        }}
                        fontSize={13}
                      >
                        {`Σ= ${new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(sumGastos)}`}
                      </Typography>
                    </Stack>
                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Fecha
                            </TableCell>

                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Descripcion
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 12 }}
                            >
                              Monto
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.gastos.map((row) => {
                            return (
                              <TableRow key={row.id}>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {row.id}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {moment(row.fecha).format("L")}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {row.description}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.entradas)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>

                <hr />

                <div>
                  <Typography
                    sx={{
                      color: "#00a152",
                      textAlign: "center",
                      marginBottom: 1,
                    }}
                    fontSize={16}
                  >
                    Ingresos:
                  </Typography>

                  <Stack spacing={2}>
                    <div>
                      <Stack direction={"row"} justifyContent="space-between">
                        <Typography
                          sx={{
                            color: "#2196f3",
                            textAlign: "left",
                          }}
                          fontSize={13}
                        >
                          Recuperación:
                        </Typography>
                        <Typography
                          className="text-dark"
                          sx={{
                            textAlign: "left",
                          }}
                          fontSize={13}
                        >
                          {`Σ= ${new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumRecuperacion)}`}
                        </Typography>
                      </Stack>
                      <TableContainer>
                        <Table aria-label="simple table" size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 12 }}
                              >
                                #
                              </TableCell>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 12 }}
                              >
                                Fecha
                              </TableCell>

                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 12 }}
                              >
                                Concepto
                              </TableCell>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 12 }}
                              >
                                Monto
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.recuperacion.map((row) => {
                              return (
                                <TableRow key={row.id}>
                                  <TableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {row.id}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {moment(row.fecha).format("L")}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {row.concepto}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {new Intl.NumberFormat("es-NI", {
                                      style: "currency",
                                      currency: "NIO",
                                    }).format(row.totalRecibido)}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Stack>
                </div>

                <hr />

                <div>
                  <Typography
                    sx={{
                      color: "#00a152",
                      textAlign: "center",
                      marginBottom: 1,
                    }}
                    fontSize={16}
                  >
                    Consolidado:
                  </Typography>

                  <TableContainer>
                    <Table aria-label="simple table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="text-primary fw-bold"
                            style={{ fontSize: 12 }}
                          >
                            Fecha
                          </TableCell>
                          <TableCell
                            align="left"
                            className="text-primary fw-bold"
                            style={{ fontSize: 12 }}
                          >
                            Descripción
                          </TableCell>

                          <TableCell
                            align="center"
                            className="text-primary fw-bold"
                            style={{ fontSize: 12 }}
                          >
                            Entrada
                          </TableCell>

                          <TableCell
                            align="center"
                            className="text-primary fw-bold"
                            style={{ fontSize: 12 }}
                          >
                            Salida
                          </TableCell>

                          <TableCell
                            align="center"
                            className="text-primary fw-bold"
                            style={{ fontSize: 12 }}
                          >
                            Saldo
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow key={1}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            DESEMBOLSOS DEL DIA
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            -
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(sumDesembolsos)}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            -
                          </TableCell>
                        </TableRow>

                        <TableRow key={2}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            GASTOS
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            -
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(sumGastos)}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            -
                          </TableCell>
                        </TableRow>

                        <TableRow key={3}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            ANULACIONES DE ABONOS
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            -
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(sumAbonosAnulados)}
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            -
                          </TableCell>
                        </TableRow>

                        <TableRow key={4}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            RECUPERACION DEL DIA
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(sumRecuperacion)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            -
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            -
                          </TableCell>
                        </TableRow>

                        <TableRow key={10}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 14, fontWeight: "bold" }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 14, fontWeight: "bold" }}
                            className="text-dark"
                          >
                            CIERRE DEL DIA
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(totalEntradas)}
                          </TableCell>

                          <TableCell
                            align="center"
                            className="text-dark"
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(totalSalidas)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(cierre)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            )}
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default CierreCajaDetallado;
