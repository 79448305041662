import React, { useState, useEffect, useContext } from "react";
import { Container, Paper, Typography, Stack, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faCalendar,
  faCalendarCheck,
  faCalendarXmark,
  faChartLine,
  faCoins,
  faFileInvoiceDollar,
  faHandHoldingDollar,
  faMoneyBillTransfer,
  faMoneyBillTrendUp,
  faSackDollar,
  faScaleBalanced,
  faScaleUnbalanced,
  faScaleUnbalancedFlip,
  faUserCheck,
  faUsers,
  faWallet,
  faChartSimple,
  faHouseLaptop,
  faCashRegister,
} from "@fortawesome/free-solid-svg-icons";
import CallerSelector from "../../components/CallerSelector";
import SelectorClientsGestor from "./selectores/selectoresCartera/SelectorClientsGestor";
import SelectorCreditsByGestor from "./selectores/selectoresCartera/SelectorCreditsByGestor";
import SelectorCobroDiario from "./selectores/selectoresCartera/SelectorCobroDiario";
import SelectorRecuperacion from "./selectores/selectoresCartera/SelectorRecuperacion";
import SelectorDesembolsos from "./selectores/selectoresCartera/SelectorDesembolsos";
import SelectorSaldosCartera from "./selectores/selectoresCartera/SelectorSaldosCartera";
import SelectorCreditosVencidos from "./selectores/selectoresCartera/SelectorCreditosVencidos";
import SelectorAccountStatus from "./selectores/selectoresCartera/SelectorAccountStatus";
import SelectorCierreDiario from "./selectores/selectoresCartera/SelectorCierreDiario";
import SelectorEstadoResultado from "./selectores/selectoresCartera/SelectorEstadoResultado";
import SelectorCreditsCancelled from "./selectores/selectoresCartera/SelectorCreditsCancelled";
import SelectorVencimientosProyectados from "./selectores/selectoresCartera/SelectorVencimientosProyectados";
import SelectorBalanzaComprobacion from "./selectores/selectoresAdministrativos/SelectorBalanzaComprobacion";
import SelectorEstadoResultContable from "./selectores/selectoresAdministrativos/SelectorEstadoResultContable";
import SelectorHistoryGastos from "./selectores/selectoresAdministrativos/SelectorHistoryGastos";
import SelectorHistoryCashFlow from "./selectores/selectoresAdministrativos/SelectorHistoryCashFlow";
import SelectorLibroDiario from "./selectores/selectoresAdministrativos/SelectorLibroDiario";
import SelectorBalanceGeneral from "./selectores/selectoresAdministrativos/SelectorBalanceGeneral";
import SelectorIngresosFinancieros from "./selectores/selectoresCartera/SelectorIngresosFinancieros";
import SelectorGarantias from "./selectores/selectoresCartera/SelectorGarantias";
import { DataContext } from "../../context/DataContext";
import { isAccess } from "../../helpers/Helpers";

const Reports = () => {
  const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);
  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack direction={"row"}>
            <Typography
              color={"#00a152"}
              textAlign="left"
              variant="h4"
              fontWeight={"bold"}
            >
              <FontAwesomeIcon
                icon={faWallet}
                style={{ marginRight: 20 }}
                className="fa-beat"
              />
            </Typography>
            <Typography
              color={"#00a152"}
              textAlign="left"
              variant="h4"
              fontWeight={"bold"}
              className="d-none d-sm-block"
            >
              Reportes de Cartera
            </Typography>
          </Stack>

          <hr />

          <Grid container spacing={2} justifyContent="center">
            {isAccess(access, "RCLIENTS") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faUsers}
                  text="Reporte de Clientes"
                  modalTitle="Reporte de Clientes"
                >
                  <SelectorClientsGestor />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CallerSelector
                icon={faHouseLaptop}
                text="Reporte de Garantias"
                modalTitle="Reporte de Garantias"
              >
                <SelectorGarantias />
              </CallerSelector>
            </Grid>

            {isAccess(access, "RCREDXGEST") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faHandHoldingDollar}
                  text="Créditos por Gestor"
                  modalTitle="Créditos por Gestor"
                >
                  <SelectorCreditsByGestor />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}
            {isAccess(access, "RCOBDIARIO") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faCalendarCheck}
                  text="Cobro Diario"
                  modalTitle="Cobro Diario"
                >
                  <SelectorCobroDiario />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}
            {isAccess(access, "RECUPERAC") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faUserCheck}
                  text="Recuperación"
                  modalTitle="Reporte de Recuperacion"
                >
                  <SelectorRecuperacion />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}

            {isAccess(access, "RECUPERAC") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faChartSimple}
                  text="Ingresos Financieros"
                  modalTitle="Ingresos Financieros"
                >
                  <SelectorIngresosFinancieros />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}

            {isAccess(access, "RDESEMBOLSO") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faWallet}
                  text="Desembolsos"
                  modalTitle="Reporte de Desembolsos"
                >
                  <SelectorDesembolsos />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}
            {isAccess(access, "RSALCART") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faCoins}
                  text="Saldos Cartera"
                  modalTitle="Reporte Saldos Cartera"
                >
                  <SelectorSaldosCartera />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}
            {isAccess(access, "RCREDVENC") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faCalendarXmark}
                  text="Créditos Vencidos"
                  modalTitle="Reporte Créditos Vencidos"
                >
                  <SelectorCreditosVencidos />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}
            {isAccess(access, "RVENCPROY") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faCalendar}
                  text="Vencimientos Proyectados"
                  modalTitle="Reporte Vencimientos proyectados"
                >
                  <SelectorVencimientosProyectados />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}
            {isAccess(access, "RCREDCANC") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faMoneyBillTrendUp}
                  text="Créditos Cancelados"
                  modalTitle="Reporte Créditos Cancelados"
                >
                  <SelectorCreditsCancelled />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}
            {isAccess(access, "RACCOUNTSTS") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faSackDollar}
                  text="Estado de Cuenta"
                  modalTitle="Reporte de Estado de Cuenta"
                >
                  <SelectorAccountStatus />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Paper>

        <Paper
          style={{
            marginTop: 20,
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack direction={"row"}>
            <Typography
              color={"#00a152"}
              textAlign="left"
              variant="h4"
              fontWeight={"bold"}
            >
              <FontAwesomeIcon
                icon={faScaleUnbalanced}
                style={{ marginRight: 20 }}
                className="fa-beat"
              />
            </Typography>
            <Typography
              color={"#00a152"}
              textAlign="left"
              variant="h4"
              fontWeight={"bold"}
              className="d-none d-sm-block"
            >
              Reportes Administrativos
            </Typography>
          </Stack>

          <hr />

          <Grid container spacing={2} justifyContent="center">
            {isAccess(access, "RBOXCLOSE") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faCashRegister}
                  text="Cierre de Caja"
                  modalTitle="Reporte de Cierre de Caja"
                >
                  <SelectorCierreDiario />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}

            {isAccess(access, "REP HGASTO") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faFileInvoiceDollar}
                  text="Historial de Gastos"
                  modalTitle="Reporte Historial de Gastos"
                >
                  <SelectorHistoryGastos />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}

            {isAccess(access, "CASHFLOW") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faMoneyBillTransfer}
                  text="Flujo de Efectivo"
                  modalTitle="Reporte Flujo de Efectivo"
                >
                  <SelectorHistoryCashFlow />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}

            {isAccess(access, "REP HLDIADIO") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faBookOpen}
                  text="Libro Diario"
                  modalTitle="Libro Diario"
                >
                  <SelectorLibroDiario />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}

            {isAccess(access, "REP BALC") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faScaleUnbalancedFlip}
                  text="Balanza de Comprobación"
                  modalTitle="Balanza de Comprobación"
                >
                  <SelectorBalanzaComprobacion />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}

            {isAccess(access, "REP ERESULT") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faChartLine}
                  text="Estado de Resultado"
                  modalTitle="Reporte Estado de Resultado"
                >
                  <SelectorEstadoResultContable />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}

            {isAccess(access, "REP BALGRAL") ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CallerSelector
                  icon={faScaleBalanced}
                  text="Balance General"
                  modalTitle="Reporte Balance General"
                >
                  <SelectorBalanceGeneral />
                </CallerSelector>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default Reports;
