import React, { useState, useEffect, useContext } from "react";
import { Typography, IconButton, Tooltip, Stack, Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCashRegister } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getRuta, simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import { DataContext } from "../../../context/DataContext";
import { getTotalCobradoAsync } from "../../../api/Dashboard";

const RecuperacionDiaria = ({ selectedSucursal }) => {
  const [totalCobrado, setTotalCobrado] = useState(0);
  const { setIsLoading, setIsUnautorized } = useContext(DataContext);
  let ruta = getRuta();
  const token = getToken();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getTotalCobradoAsync(token, selectedSucursal);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);
      setTotalCobrado(result.data.result);
    })();
  }, [selectedSucursal]);

  return (
    <div>
      <Stack
        direction={"row"}
        style={{ padding: 10 }}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Tooltip title="Ver Créditos">
          <IconButton
            sx={{ border: 1, borderColor: "rgba(75, 192, 192, 1)" }}
            style={{
              width: 100,
              height: 100,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
            }}
            onClick={() => navigate(`${ruta}/clients`)}
          >
            <FontAwesomeIcon
              icon={faCashRegister}
              style={{
                fontSize: 60,
                color: "rgba(75, 192, 192, 1)",
                opacity: 0.3,
                padding: 10,
              }}
            />
          </IconButton>
        </Tooltip>
        <Typography
          align="right"
          style={{
            color: "rgba(75, 192, 192, 1)",
            fontWeight: 800,
            fontSize: 30,
          }}
          paragraph
        >
          {new Intl.NumberFormat("es-NI", {
            style: "currency",
            currency: "NIO",
          }).format(totalCobrado)}
        </Typography>
      </Stack>
      <Divider />
      <Typography
        variant="subtitle1"
        align="center"
        style={{ color: "rgba(75, 192, 192, 1)", fontSize: 20 }}
      >
        Recuperación Diaria
      </Typography>
    </div>
  );
};

export default RecuperacionDiaria;
