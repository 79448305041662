import React, { useState, useEffect, useContext } from "react";

import {
  Container,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  Stack,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import moment from "moment";
import { DataContext } from "../../../context/DataContext";
import { getToken } from "../../../api/Account";
import { isAccess, simpleMessage } from "../../../helpers/Helpers";
import NoData from "../../../components/NoData";
import { getCajaMovAsync } from "../../../api/Cash";
import { PaginationComponent } from "../../../components/PaginationComponent";
import SmallModal from "../../../components/modals/SmallModal";
import AddCajaMovment from "./AddCajaMovment";
import { getSucursalByUserAsync } from "../../../api/Users";

const Caja = () => {
  const [cajaMovList, setCajaMovList] = useState([]);
  const [localReload, setLocalReload] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [isEntrada, setIsEntrada] = useState(false);

  const [sucursalList, setSucursalList] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState("");

  const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);
  const token = getToken();

  useEffect(() => {
    getSucursals();
  }, [localReload]);

  const addMovment = (value) => {
    setIsEntrada(value);
    setShowModal(true);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = cajaMovList.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(result.data.result);
    setSelectedSucursal(result.data.result[0].id);

    await getCajaMovs(result.data.result[0].id);
  };

  const getCajaMovs = async (sucursal) => {
    setIsLoading(true);
    const result = await getCajaMovAsync(token, sucursal);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);

    setCajaMovList(result.data.result);
  };

  const onChangeSucursal = (value) => {
    setSelectedSucursal(value);
    getCajaMovs(value);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack direction={"row"} justifyContent="space-between">
            <Typography
              color={"#00a152"}
              variant="h4"
              fontWeight={"bold"}
              className="d-none d-sm-block"
            >
              Movimientos del Mes en Caja
            </Typography>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <FormControl style={{ textAlign: "left", width: 200 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Sucursal
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  onChange={(e) => onChangeSucursal(e.target.value)}
                  value={selectedSucursal}
                  label="Sucursal"
                >
                  {sucursalList.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {isAccess(access, "CAJA CREATE") ? (
                <Stack spacing={1} direction="row">
                  <Button
                    onClick={() => {
                      addMovment(true);
                    }}
                    startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                    variant="outlined"
                    style={{
                      color: "#00a152",
                      borderColor: "#00a152",
                    }}
                  >
                    Efectivo
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      borderColor: "#f50057",
                      color: "#f50057",
                    }}
                    startIcon={<FontAwesomeIcon icon={faCircleMinus} />}
                    onClick={() => {
                      addMovment(false);
                    }}
                  >
                    Efectivo
                  </Button>
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>

          <hr />

          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Fecha
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Concepto
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Entrada
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Salida
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Saldo
                      </TableCell>

                      <TableCell align="left" className="text-primary fw-bold">
                        Realizado Por
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentItem.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="center" component="th" scope="row">
                            {moment(row.fecha).format("L")}
                          </TableCell>
                          <TableCell align="left">{row.description}</TableCell>
                          <TableCell align="center">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(row.entradas)}
                          </TableCell>
                          <TableCell align="center">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(row.salidas)}
                          </TableCell>
                          <TableCell align="center">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(row.saldo)}
                          </TableCell>
                          <TableCell align="left">
                            {`${row.realizadoPor.fullName.split(" ")[0]}  ${
                              row.realizadoPor.fullName.split(" ")[1]
                            }`}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <PaginationComponent
                data={cajaMovList}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>

      <SmallModal
        titulo={
          isEntrada
            ? "Entrada de Efectivo a Caja"
            : "Salida de Efectivo de Caja"
        }
        isVisible={showModal}
        setVisible={setShowModal}
      >
        <AddCajaMovment
          setShowModal={setShowModal}
          isEntrada={isEntrada}
          localReload={localReload}
          setLocalReload={setLocalReload}
          selectedSucursal={selectedSucursal}
        />
      </SmallModal>
    </div>
  );
};

export default Caja;
