import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Paper,
} from "@mui/material";
import { isEmpty } from "lodash";

import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";

import { getRol, getToken, getUser } from "../../../api/Account";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import NoData from "../../../components/NoData";
import { PrintReport } from "../../../components/PrintReport";
import moment from "moment";
import { getSucursalByUserAsync } from "../../../api/Users";
import { simpleMessage } from "../../../helpers/Helpers";
import { getEstadoResultadoAsync } from "../../../api/AdmonReport";

const ReporteEstadoResultado = () => {
  const [data, setData] = useState([]);

  const [sucursalName, setSucursalName] = useState("");

  const compRef = useRef();
  const { selectedSucursal, fechaDesde, fechaHasta, selectedGestor } =
    useParams();

  const { setIsLoading, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const token = getToken();
  const user = getUser();
  const rol = getRol();

  useEffect(() => {
    (async () => {
      let sucursals = await getSucursals();

      if (selectedSucursal === "t") {
        setSucursalName("Todas");
      } else {
        let name = sucursals.filter(
          (item) => item.id.toString() === selectedSucursal
        );
        setSucursalName(name[0].description);
      }

      const data = {
        desde: fechaDesde.toString(),
        hasta: fechaHasta.toString(),
        gestorId: selectedGestor === "t" ? 0 : selectedGestor,
        sucursalId: selectedSucursal === "t" ? 0 : selectedSucursal,
      };

      setIsLoading(true);
      const result = await getEstadoResultadoAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setData(result.data.result);
    })();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    return result.data.result;
  };

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page {   margin: 8mm; }
`;

  let sumGastosFijos = 0;
  let sumGastosVariables = 0;
  let sumGastosDirectos = 0;
  let sumGastosIndirectos = 0;
  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Typography
            sx={{
              color: "#2196f3",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {slogan}
          </Typography>

          <Typography
            sx={{
              color: "#2196f3",
              textAlign: "center",
              fontWeight: "bold",
            }}
            variant="h6"
            component="div"
          >
            Estado de Resultado
          </Typography>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>

                <Stack direction={"row"} spacing={1}>
                  <Typography fontSize={11} textAlign="left">
                    Desde:
                  </Typography>

                  <Typography fontSize={11} textAlign="left">
                    {moment(fechaDesde).format("L")}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography fontSize={11} textAlign="left">
                    Hasta:
                  </Typography>

                  <Typography fontSize={11} textAlign="left">
                    {moment(fechaHasta).format("L")}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          <ReactToPrint
            trigger={() => {
              return (
                <IconButton
                  variant="outlined"
                  style={{ position: "fixed", right: 20, top: 75 }}
                >
                  <PrintRoundedIcon
                    style={{ fontSize: 35, color: "#2979ff", width: 35 }}
                  />
                </IconButton>
              );
            }}
            content={() => compRef.current}
            pageStyle={pageStyle}
          />
        </Stack>

        <hr />
        <Container maxWidth="md" sx={{ textAlign: "center" }}>
          {isEmpty(data) ? (
            <NoData />
          ) : (
            <>
              <Paper
                style={{
                  padding: 20,
                }}
              >
                <TableContainer>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                          colSpan={5}
                        ></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow key={0}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ fontSize: 14 }}
                        >
                          Recuperacion
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(data.recuperacion)}
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                      </TableRow>

                      <TableRow key={1}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ fontSize: 14 }}
                        >
                          Anulaciones
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12, borderBottomWidth: 5 }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(data.anulaciones)}
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12, borderBottomWidth: 5 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                      </TableRow>

                      <TableRow key={3}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ fontSize: 14, color: "#00a152" }}
                        >
                          Recuperacion Neta
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12, color: "#00a152" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(data.recuperacion - data.anulaciones)}
                        </TableCell>
                      </TableRow>

                      {data.gastosFijos.length > 0 ? (
                        <>
                          <TableRow key={4}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                            >
                              Gastos Fijos
                            </TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12, color: "#00a152" }}
                            ></TableCell>
                          </TableRow>

                          {data.gastosFijos.map((row, index) => {
                            sumGastosFijos += row.monto;
                            return (
                              <TableRow key={data.gastosFijos.indexOf(row) + 5}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.descripcion}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastosFijos.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.monto)}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastosFijos.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {index === data.gastosFijos.length - 1
                                    ? new Intl.NumberFormat("es-NI", {
                                        style: "currency",
                                        currency: "NIO",
                                      }).format(sumGastosFijos)
                                    : ""}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12 }}
                                ></TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12, color: "#00a152" }}
                                ></TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}

                      {data.gastoVariables.length > 0 ? (
                        <>
                          <TableRow key={100}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                            >
                              Gastos Variables
                            </TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12, color: "#00a152" }}
                            ></TableCell>
                          </TableRow>

                          {data.gastoVariables.map((row, index) => {
                            sumGastosVariables += row.monto;
                            return (
                              <TableRow
                                key={data.gastoVariables.indexOf(row) + 101}
                              >
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.descripcion}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastoVariables.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.monto)}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastoVariables.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {index === data.gastoVariables.length - 1
                                    ? new Intl.NumberFormat("es-NI", {
                                        style: "currency",
                                        currency: "NIO",
                                      }).format(sumGastosVariables)
                                    : ""}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12 }}
                                ></TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12, color: "#00a152" }}
                                ></TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}

                      {data.gastosDirectos.length > 0 ? (
                        <>
                          <TableRow key={200}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                            >
                              Gastos Directos
                            </TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12, color: "#00a152" }}
                            ></TableCell>
                          </TableRow>

                          {data.gastosDirectos.map((row, index) => {
                            sumGastosDirectos += row.monto;
                            return (
                              <TableRow
                                key={data.gastosDirectos.indexOf(row) + 201}
                              >
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.descripcion}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastosDirectos.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.monto)}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastosDirectos.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {index === data.gastosDirectos.length - 1
                                    ? new Intl.NumberFormat("es-NI", {
                                        style: "currency",
                                        currency: "NIO",
                                      }).format(sumGastosDirectos)
                                    : ""}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12 }}
                                ></TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12, color: "#00a152" }}
                                ></TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}

                      {data.gastosIndirectos.length > 0 ? (
                        <>
                          <TableRow key={300}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                            >
                              Gastos Indirectos
                            </TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12, color: "#00a152" }}
                            ></TableCell>
                          </TableRow>

                          {data.gastosIndirectos.map((row, index) => {
                            sumGastosIndirectos += row.monto;
                            return (
                              <TableRow
                                key={data.gastosIndirectos.indexOf(row) + 301}
                              >
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.descripcion}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastosIndirectos.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.monto)}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastosIndirectos.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {index === data.gastosIndirectos.length - 1
                                    ? new Intl.NumberFormat("es-NI", {
                                        style: "currency",
                                        currency: "NIO",
                                      }).format(sumGastosIndirectos)
                                    : ""}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12 }}
                                ></TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12, color: "#00a152" }}
                                ></TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}

                      <TableRow key={400}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ fontSize: 14, color: "#f50057" }}
                        >
                          Total Gastos
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12, color: "#f50057" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(
                            sumGastosFijos +
                              sumGastosVariables +
                              sumGastosDirectos +
                              sumGastosIndirectos
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                      </TableRow>

                      <TableRow key={401}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ fontSize: 14, color: "#ffc400" }}
                        >
                          Utilidad de Operacion
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12, color: "#ffc400" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(
                            data.recuperacion -
                              data.anulaciones -
                              (sumGastosFijos +
                                sumGastosVariables +
                                sumGastosDirectos +
                                sumGastosIndirectos)
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
          )}
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte Estado de Resultado"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>

                <Stack direction={"row"} spacing={1}>
                  <Typography fontSize={11} textAlign="left">
                    Desde:
                  </Typography>

                  <Typography fontSize={11} textAlign="left">
                    {moment(fechaDesde).format("L")}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography fontSize={11} textAlign="left">
                    Hasta:
                  </Typography>

                  <Typography fontSize={11} textAlign="left">
                    {moment(fechaHasta).format("L")}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          <hr />

          <Container maxWidth="md" sx={{ textAlign: "center" }}>
            {isEmpty(data) ? (
              <NoData />
            ) : (
              <>
                <TableContainer>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                          colSpan={5}
                        ></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow key={0}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ fontSize: 14 }}
                          className="text-dark"
                        >
                          Recuperacion
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                          className="text-dark"
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(data.recuperacion)}
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                      </TableRow>

                      <TableRow key={1}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ fontSize: 14 }}
                          className="text-dark"
                        >
                          Anulaciones
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12, borderBottomWidth: 5 }}
                          className="text-dark"
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(data.anulaciones)}
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12, borderBottomWidth: 5 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                      </TableRow>

                      <TableRow key={3}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ fontSize: 14, color: "#00a152" }}
                          className="text-dark"
                        >
                          Recuperacion Neta
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12, color: "#00a152" }}
                          className="text-dark"
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(data.recuperacion - data.anulaciones)}
                        </TableCell>
                      </TableRow>

                      {data.gastosFijos.length > 0 ? (
                        <>
                          <TableRow key={4}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                              className="text-dark"
                            >
                              Gastos Fijos
                            </TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12, color: "#00a152" }}
                            ></TableCell>
                          </TableRow>

                          {data.gastosFijos.map((row, index) => {
                            return (
                              <TableRow key={data.gastosFijos.indexOf(row) + 5}>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {row.descripcion}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastosFijos.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                  className="text-dark"
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.monto)}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  className="text-dark"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastosFijos.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {index === data.gastosFijos.length - 1
                                    ? new Intl.NumberFormat("es-NI", {
                                        style: "currency",
                                        currency: "NIO",
                                      }).format(sumGastosFijos)
                                    : ""}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12 }}
                                ></TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12, color: "#00a152" }}
                                ></TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}

                      {data.gastoVariables.length > 0 ? (
                        <>
                          <TableRow key={100}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                              className="text-dark"
                            >
                              Gastos Variables
                            </TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12, color: "#00a152" }}
                            ></TableCell>
                          </TableRow>

                          {data.gastoVariables.map((row, index) => {
                            return (
                              <TableRow
                                key={data.gastoVariables.indexOf(row) + 101}
                              >
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {row.descripcion}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  className="text-dark"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastoVariables.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.monto)}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  className="text-dark"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastoVariables.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {index === data.gastoVariables.length - 1
                                    ? new Intl.NumberFormat("es-NI", {
                                        style: "currency",
                                        currency: "NIO",
                                      }).format(sumGastosVariables)
                                    : ""}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12 }}
                                ></TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12, color: "#00a152" }}
                                ></TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}

                      {data.gastosDirectos.length > 0 ? (
                        <>
                          <TableRow key={200}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                              className="text-dark"
                            >
                              Gastos Directos
                            </TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12, color: "#00a152" }}
                            ></TableCell>
                          </TableRow>

                          {data.gastosDirectos.map((row, index) => {
                            return (
                              <TableRow
                                key={data.gastosDirectos.indexOf(row) + 201}
                              >
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {row.descripcion}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  className="text-dark"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastosDirectos.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.monto)}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  className="text-dark"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastosDirectos.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {index === data.gastosDirectos.length - 1
                                    ? new Intl.NumberFormat("es-NI", {
                                        style: "currency",
                                        currency: "NIO",
                                      }).format(sumGastosDirectos)
                                    : ""}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12 }}
                                ></TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12, color: "#00a152" }}
                                ></TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}

                      {data.gastosIndirectos.length > 0 ? (
                        <>
                          <TableRow key={300}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 14 }}
                              className="text-dark"
                            >
                              Gastos Indirectos
                            </TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12, color: "#00a152" }}
                            ></TableCell>
                          </TableRow>

                          {data.gastosIndirectos.map((row, index) => {
                            return (
                              <TableRow
                                key={data.gastosIndirectos.indexOf(row) + 301}
                              >
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                  className="text-dark"
                                >
                                  {row.descripcion}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  className="text-dark"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastosIndirectos.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.monto)}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  className="text-dark"
                                  style={{
                                    fontSize: 12,
                                    borderBottomWidth:
                                      index === data.gastosIndirectos.length - 1
                                        ? 5
                                        : 1,
                                  }}
                                >
                                  {index === data.gastosIndirectos.length - 1
                                    ? new Intl.NumberFormat("es-NI", {
                                        style: "currency",
                                        currency: "NIO",
                                      }).format(sumGastosIndirectos)
                                    : ""}
                                </TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12 }}
                                ></TableCell>

                                <TableCell
                                  align="center"
                                  scope="row"
                                  style={{ fontSize: 12, color: "#00a152" }}
                                ></TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}

                      <TableRow key={400}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ fontSize: 14, color: "#f50057" }}
                          className="text-dark"
                        >
                          Total Gastos
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12, color: "#f50057" }}
                          className="text-dark"
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(
                            sumGastosFijos +
                              sumGastosVariables +
                              sumGastosDirectos +
                              sumGastosIndirectos
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                      </TableRow>

                      <TableRow key={401}>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ fontSize: 14, color: "#ffc400" }}
                          className="text-dark"
                        >
                          Utilidad de Operacion
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12 }}
                        ></TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 12, color: "#ffc400" }}
                          className="text-dark"
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(
                            data.recuperacion -
                              data.anulaciones -
                              (sumGastosFijos +
                                sumGastosVariables +
                                sumGastosDirectos +
                                sumGastosIndirectos)
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default ReporteEstadoResultado;
