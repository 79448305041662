import React, { useState, useContext } from "react";
import { Typography, Menu, MenuItem, Toolbar, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faMoon,
  faSignOutAlt,
  faSun,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import { DataContext } from "../context/DataContext";
import { getRuta, simpleMessage } from "../helpers/Helpers";
import {
  apiLogOut,
  changeThemeAsync,
  getRol,
  getToken,
  getUser,
} from "../api/Account";

const NavBarElements = () => {
  let navigate = useNavigate();
  const user = getUser();
  const rol = getRol();
  const token = getToken();
  const rout = getRuta();

  const { isDarkMode, setIsDarkMode, setIsLogged, setIsLoading } =
    useContext(DataContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    const result = apiLogOut();
    if (!result.statusResponse) {
      simpleMessage("No se pudo cerrar sesion, intente de nuevo", "error");
    }
    setIsDarkMode(false);
    setIsLogged(false);
    navigate(rout);
  };

  const myAccount = () => {
    navigate(`${rout}/account`);
  };

  const changeTheme = async () => {
    setIsLoading(true);
    const resul = await changeThemeAsync(token);
    if (!resul.statusResponse) {
      setIsLoading(false);
      simpleMessage("No se pudo cambiar el tema", "error");
      return;
    }

    setIsDarkMode(!isDarkMode);
    setIsLoading(false);
  };

  return (
    <Toolbar style={{ width: "100%" }}>
      <Typography variant="h6" style={{ flexGrow: 1 }} />

      <div className="block-profile-header">
        <div className="block-img">
          <Avatar sx={{ bgcolor: "#00a152" }}>
            {user ? `${user.split(" ")[0][0]}${user.split(" ")[1][0]}` : ""}
          </Avatar>
        </div>

        <button
          type="button"
          className="cont-des"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
        >
          <div
            className="fx-title"
            style={{ color: isDarkMode ? "#fff" : "#090909" }}
          >
            {/* <Typography variant="h5">{`${user}`}</Typography> */}
            <Typography
              variant="h5"
              style={{ color: isDarkMode ? "#2979ff" : "" }}
            >
              {user ? `${user.split(" ")[0]} ${user.split(" ")[1][0]}.` : ""}
            </Typography>

            <FontAwesomeIcon
              icon={faChevronDown}
              style={{ color: isDarkMode ? "#2979ff" : "" }}
            />
          </div>

          <Typography
            fontSize={12}
            style={{ color: isDarkMode ? "#2979ff" : "" }}
          >
            {rol.substring(0, 7)}
          </Typography>
        </button>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={myAccount}
            style={{ color: isDarkMode ? "#2979ff" : "" }}
          >
            <FontAwesomeIcon icon={faUserCog} style={{ marginRight: 20 }} />
            Mi cuenta
          </MenuItem>
          <MenuItem
            onClick={changeTheme}
            style={{ color: isDarkMode ? "#2979ff" : "" }}
          >
            <FontAwesomeIcon
              icon={isDarkMode ? faMoon : faSun}
              style={{ marginRight: 20 }}
            />
            {isDarkMode ? "Tema Claro" : "Tema Oscuro"}
          </MenuItem>
          <MenuItem
            onClick={logOut}
            style={{ color: isDarkMode ? "#2979ff" : "" }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: 20 }} />
            Cerrar Sesion
          </MenuItem>
        </Menu>
      </div>
    </Toolbar>
  );
};

export default NavBarElements;
