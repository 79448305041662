import React, { useState, useEffect, useContext } from 'react';
import {
	Container,
	Paper,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Table,
	Typography,
	Stack,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { isEmpty, isUndefined } from 'lodash';
import moment from 'moment';
import { DataContext } from '../../../context/DataContext';
import { getToken } from '../../../api/Account';
import { isAccess, simpleMessage } from '../../../helpers/Helpers';
import NoData from '../../../components/NoData';
import { PaginationComponent } from '../../../components/PaginationComponent';
import { getSucursalByUserAsync } from '../../../api/Users';
import { isInicioContableAsync } from '../../../api/Contabilidad';
import AperturaContable from '../contabilidad/AperturaContable';
import { getCashFlowsAsync } from '../../../api/CashFlows';

import RetirarCapital from './RetirarCapital';
import SmallModal from '../../../components/modals/SmallModal';
import InyectarCapital from './InyectarCapital';

const CashFlow = () => {
	const [cashFlowList, setCashFlowList] = useState([]);
	const [localReload, setLocalReload] = useState(false);
	const [isApertura, setIsApertura] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [isEntrada, setIsEntrada] = useState(false);
	const [sucursalList, setSucursalList] = useState([]);
	const [selectedTipoFlujo, setSelectedTipoFlujo] = useState(5);

	var date = new Date();
	const [fechaDesde, setFechaDesde] = useState(date);

	const [sumEntradas, setSumEntradas] = useState(0);
	const [sumSalidas, setSumSalidas] = useState(0);

	const { setIsLoading, setIsUnautorized, access, selectedSucursal, setSelectedSucursal } =
		useContext(DataContext);
	const token = getToken();

	useEffect(() => {
		(async () => {
			isAperturaAsync();
			let suc = await getSucursals();
			getCashFlows(suc, 5);
		})();
	}, [localReload]);

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsperPage] = useState(10);
	const indexLast = currentPage * itemsperPage;
	const indexFirst = indexLast - itemsperPage;
	const currentItem = cashFlowList.slice(indexFirst, indexLast);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(result.data.result);

		if (selectedSucursal === 0 || isUndefined(selectedSucursal)) {
			setSelectedSucursal(result.data.result[0].id);
			return result.data.result[0].id;
		}
		return result.data.result[0].id;
	};

	const onChangeSucursal = (value) => {
		setSelectedSucursal(value);
		getCashFlows(value, selectedTipoFlujo);
	};

	const onChangeTipoFlujo = (value) => {
		setSelectedTipoFlujo(value);
		getCashFlows(selectedSucursal, value);
	};

	const addMovment = (value) => {
		setIsEntrada(value);
		setShowModal(true);
	};

	const isAperturaAsync = async () => {
		setIsLoading(true);

		const result = await isInicioContableAsync(token);
		if (!result.statusResponse) {
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsApertura(result.data.result);
		setIsLoading(false);
		return result.data.result;
	};

	const getCashFlows = async (sucursal, tf) => {
		setIsLoading(true);
		const data = {
			sucursalId: sucursal,
			cuentaId: tf,
			fecha: moment(fechaDesde).format('YYYY-MM-DD').toString()
		};

		const result = await getCashFlowsAsync(token, data);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setCashFlowList(result.data.result);
		sumatorias(result.data.result);
	};


	const sumatorias = (data) => {
		let entradas = 0;
		let salidas = 0;
		if (data.length > 0) {
			data.map((asiento) => {
				entradas += asiento.entradas;
				salidas += asiento.salidas;
			});
			setSumEntradas(entradas);

			setSumSalidas(salidas);
		}
	};

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					{isApertura ? (
						<AperturaContable
							localReload={localReload}
							setLocalReload={setLocalReload}
						/>
					) : (
						<>
							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								justifyContent="space-between"
								alignItems={'center'}
							>
								<Stack alignItems={'left'} spacing={1}>
									<Typography
										color={'#00a152'}
										variant="h4"
										fontWeight={'bold'}
										className="d-none d-sm-block"
									>
										Transacciones del dia en Caja
									</Typography>

									<Stack
										direction={{ xs: 'column', sm: 'column', md: 'row' }}
										justifyContent="space-between"
										spacing={1}
									>
										<FormControl
											style={{ textAlign: 'left', minWidth: 100 }}
											fullWidth
										>
											<InputLabel id="demo-simple-select-standard-label">
												Sucursal
											</InputLabel>
											<Select
												labelId="demo-simple-select-standard-label"
												id="demo-simple-select-standard"
												onChange={(e) => onChangeSucursal(e.target.value)}
												value={selectedSucursal}
												label="Sucursal"
											>
												{sucursalList.map((item) => {
													return (
														<MenuItem key={item.id} value={item.id}>
															{item.description}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>

										<FormControl
											style={{ textAlign: 'left', minWidth: 100 }}
											fullWidth
										>
											<InputLabel id="demo-simple-select-standard-label">
												Cuenta
											</InputLabel>
											<Select
												labelId="demo-simple-select-standard-label"
												id="demo-simple-select-standard"
												onChange={(e) => onChangeTipoFlujo(e.target.value)}
												value={selectedTipoFlujo}
												label="Cuenta"
											>
												<MenuItem value={5}>EFECTIVO EN CAJA 1</MenuItem>
												<MenuItem value={145}>EFECTIVO EN CAJA 2</MenuItem>
												<MenuItem value={146}>EFECTIVO EN CAJA CHICA</MenuItem>
												<MenuItem value={143}>EFECTIVO EN BANCOS</MenuItem>
											</Select>
										</FormControl>
									</Stack>
								</Stack>

								<Stack
									direction={{ xs: 'column', sm: 'row' }}
									justifyContent="space-between"
									spacing={2}
									alignItems={'center'}
								>
									<Stack spacing={1} direction="column">
										{isAccess(access, 'CAJA CREATE') ? (
											<Stack
												spacing={1}
												direction={{ xs: 'column', sm: 'row' }}
											>
												<Button
													fullWidth
													onClick={() => {
														addMovment(true);
													}}
													startIcon={
														<FontAwesomeIcon icon={faCirclePlus} />
													}
													variant="outlined"
													style={{
														color: '#00a152',
														borderColor: '#00a152'
													}}
												>
													Capital
												</Button>
												<Button
													fullWidth
													variant="outlined"
													style={{
														borderColor: '#f50057',
														color: '#f50057'
													}}
													startIcon={
														<FontAwesomeIcon icon={faCircleMinus} />
													}
													onClick={() => {
														addMovment(false);
													}}
												>
													Capital
												</Button>
											</Stack>
										) : (
											<></>
										)}

										<Stack
											direction={{ xs: 'column', sm: 'row' }}
											justifyContent="center"
											spacing={1}
										>
											<Typography
												color={'#2979ff'}
												fontWeight={'bold'}
												fontSize={12}
											>
												Saldo en Caja
											</Typography>
											<Typography
												color={'#ffc400'}
												fontWeight={'bold'}
												fontSize={12}
											>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(sumEntradas - sumSalidas)}
											</Typography>
										</Stack>

										<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
											<Stack
												direction={{ xs: 'column', sm: 'row' }}
												spacing={1}
											>
												<Typography
													textAlign={'left'}
													fontWeight={'bold'}
													fontSize={12}
												>
													∑ Entradas:
												</Typography>
												<Typography
													textAlign={'left'}
													color={'#00a152'}
													fontWeight={'bold'}
													fontSize={12}
												>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(sumEntradas)}
												</Typography>
											</Stack>
											<Stack
												direction={{ xs: 'column', sm: 'row' }}
												spacing={1}
											>
												<Typography
													textAlign={'left'}
													fontWeight={'bold'}
													fontSize={12}
												>
													∑ Salidas:
												</Typography>
												<Typography
													textAlign={'left'}
													color={'#00a152'}
													fontWeight={'bold'}
													fontSize={12}
												>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(sumSalidas)}
												</Typography>
											</Stack>
										</Stack>
									</Stack>
								</Stack>
							</Stack>

							<hr />

							{isEmpty(currentItem) ? (
								<NoData />
							) : (
								<Stack spacing={2}>
									<TableContainer>
										<Table aria-label="simple table" size="small">
											<TableHead>
												<TableRow>
													<TableCell
														align="center"
														className="text-primary fw-bold"
													>
														Fecha
													</TableCell>
													<TableCell
														align="left"
														className="text-primary fw-bold"
													>
														Concepto
													</TableCell>
													<TableCell
														align="center"
														className="text-primary fw-bold"
													>
														Entrada
													</TableCell>
													<TableCell
														align="center"
														className="text-primary fw-bold"
													>
														Salida
													</TableCell>

													<TableCell
														align="left"
														className="text-primary fw-bold"
													>
														Realizado Por
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{currentItem.map((row) => {
													return (
														<TableRow key={row.id}>
															<TableCell
																align="center"
																component="th"
																scope="row"
															>
																{moment(row.fecha).format('L')}
															</TableCell>
															<TableCell align="left">
																{row.description}
															</TableCell>

															<TableCell align="center">
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(row.entradas)}
															</TableCell>
															<TableCell align="center">
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(row.salidas)}
															</TableCell>

															<TableCell align="left">
																{`${
																	row.realizadoPorx.split(' ')[0]
																}  ${
																	row.realizadoPorx.split(' ')[1]
																}`}
															</TableCell>
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									</TableContainer>
									<PaginationComponent
										data={cashFlowList}
										paginate={paginate}
										itemsperPage={itemsperPage}
									/>
								</Stack>
							)}
						</>
					)}
				</Paper>
			</Container>

			<SmallModal
				titulo={isEntrada ? 'Inyectar Capital' : 'Retirar Capital'}
				isVisible={showModal}
				setVisible={setShowModal}
			>
				{isEntrada ? (
					<InyectarCapital
						setShowModal={setShowModal}
						localReload={localReload}
						setLocalReload={setLocalReload}
						selectedSucursal={selectedSucursal}
						selectedTipoFlujo={selectedTipoFlujo}
					/>
				) : (
					<RetirarCapital
						setShowModal={setShowModal}
						localReload={localReload}
						setLocalReload={setLocalReload}
						selectedSucursal={selectedSucursal}
						setSelectedSucursal={setSelectedSucursal}
						selectedTipoFlujo={selectedTipoFlujo}
					/>
				)}
			</SmallModal>
		</div>
	);
};

export default CashFlow;
