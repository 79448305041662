import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Divider,
} from "@mui/material";
import { isEmpty } from "lodash";

import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";

import { getRol, getToken, getUser } from "../../../api/Account";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import NoData from "../../../components/NoData";

import { PrintReport } from "../../../components/PrintReport";
import moment from "moment";
import { getSucursalByUserAsync } from "../../../api/Users";
import { simpleMessage } from "../../../helpers/Helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx/xlsx.mjs";
import { getGastosHistoryAsync } from "../../../api/AdmonReport";

const ReporteGastosHistory = () => {
  const [data, setData] = useState([]);

  const [sucursalName, setSucursalName] = useState("");
  const [sucursalList, setSucursalList] = useState([]);
  const compRef = useRef();
  const { selectedSucursal, fechaDesde, fechaHasta, selectedGestor } =
    useParams();

  const { setIsLoading, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const token = getToken();
  const user = getUser();
  const rol = getRol();

  useEffect(() => {
    (async () => {
      let sucursals = await getSucursals();

      if (selectedSucursal === "t") {
        setSucursalName("Todas");
      } else {
        let name = sucursals.filter(
          (item) => item.id.toString() === selectedSucursal
        );
        setSucursalName(name[0].description);
      }

      const data = {
        sucursalId: selectedSucursal === "t" ? 0 : selectedSucursal,
        desde: fechaDesde.toString(),
        hasta: fechaHasta.toString(),
        gestorId: selectedGestor === "t" ? 0 : selectedGestor,
      };

      setIsLoading(true);
      const result = await getGastosHistoryAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);

      setData(result.data.result);
    })();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(
      selectedSucursal === "t"
        ? result.data.result
        : result.data.result.filter((g) => g.id.toString() === selectedSucursal)
    );

    return result.data.result;
  };

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page {  margin: 3mm; }
`;

  const exportExcel = () => {
    let exp = [];
    data.map((item) => {
      let obj = {
        id: item.id,
        fecha: item.fecha,
        description: item.description,
        monto: item.monto,
        payWith: item.payWith,
        realizadoPor: item.realizadoPor,
        sucursal: item.sucursal.description,
      };

      exp.push(obj);
    });
    var bk = XLSX.utils.book_new(),
      bs = XLSX.utils.json_to_sheet(exp);

    XLSX.utils.book_append_sheet(bk, bs, "historialGastos");
    XLSX.writeFile(bk, "HistorialGastos.xlsx");
  };

  let montoTotalConsol = 0;

  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Stack
            direction={"row"}
            justifyContent="space-between"
            paddingLeft={5}
            paddingRight={5}
            marginTop={1}
            marginBottom={1}
          >
            <IconButton variant="outlined" onClick={() => exportExcel()}>
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                style={{ fontSize: 40, color: "#00a152", width: 40 }}
              />
            </IconButton>

            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {slogan}
              </Typography>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                variant="h6"
                component="div"
              >
                Reporte Gastos
              </Typography>
            </Stack>

            <ReactToPrint
              trigger={() => {
                return (
                  <IconButton variant="outlined">
                    <PrintRoundedIcon
                      style={{ fontSize: 40, color: "#2979ff", width: 40 }}
                    />
                  </IconButton>
                );
              }}
              content={() => compRef.current}
              pageStyle={pageStyle}
            />
          </Stack>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Desde: ${moment(fechaDesde).format("L")}`}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Hasta:  ${moment(fechaHasta).format("L")}`}
                </Typography>
              </Stack>

              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>
        </Stack>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <hr />

          {isEmpty(data) ? (
            <NoData />
          ) : (
            <>
              {sucursalList.map((suc) => {
                const sSucursal = data.filter(
                  (c) => c.sucursal.description === suc.description
                );
                let momtoTotal = 0;
                return isEmpty(sSucursal) ? (
                  <></>
                ) : (
                  <Stack key={suc.description}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        Sucursal:
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        {`${suc.description}`}
                      </Typography>
                    </Stack>

                    <Divider />

                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 13 }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 13 }}
                            >
                              Fecha
                            </TableCell>
                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 13 }}
                            >
                              Descripcion
                            </TableCell>

                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 13 }}
                            >
                              Cuenta
                            </TableCell>

                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 13 }}
                            >
                              Monto
                            </TableCell>

                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 13 }}
                            >
                              Realizado por
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sSucursal.map((row) => {
                            momtoTotal += row.monto;
                            montoTotalConsol += row.monto;
                            return (
                              <TableRow key={row.id}>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.id}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {moment(row.fecha).format("L")}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.description}
                                </TableCell>

                                <TableCell
                                  align="left"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.payWith}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.monto)}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ fontSize: 11 }}
                                >
                                  {`${row.realizadoPor.split(" ")[0]}  ${
                                    row.realizadoPor.split(" ")[1]
                                  }`}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <hr />
                    <Stack
                      direction="row"
                      flex="row"
                      justifyContent="space-around"
                    >
                      <Stack textAlign="center">
                        <Typography
                          style={{
                            fontWeight: "bold",
                            color: "#03a9f4",
                            fontSize: 11,
                          }}
                        >
                          Total de Transacciones
                        </Typography>
                        <Typography style={{ fontSize: 11 }}>
                          {sSucursal.length}
                        </Typography>
                      </Stack>

                      <Stack textAlign="center">
                        <Typography
                          style={{
                            fontWeight: "bold",
                            color: "#03a9f4",
                            fontSize: 11,
                          }}
                        >
                          Monto Total
                        </Typography>
                        <Typography style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(momtoTotal)}
                        </Typography>
                      </Stack>
                    </Stack>
                    <hr />
                  </Stack>
                );
              })}
            </>
          )}

          {selectedSucursal === "t" ? (
            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                }}
              >
                Consolidado
              </Typography>
              <hr />
              <Stack direction="row" flex="row" justifyContent="space-around">
                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Consolidado de Transacciones
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {data.length}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Consolidado Monto Total
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(montoTotalConsol)}
                  </Typography>
                </Stack>
              </Stack>
              <hr />
            </Stack>
          ) : (
            <></>
          )}
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte de Gastos"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Desde: ${moment(fechaDesde).format("L")} `}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Hasta:  ${moment(fechaHasta).format("L")}`}
                </Typography>
              </Stack>

              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <hr />

            {isEmpty(data) ? (
              <NoData />
            ) : (
              <>
                {sucursalList.map((suc) => {
                  const sSucursal = data.filter(
                    (c) => c.sucursal.description === suc.description
                  );
                  let momtoTotal = 0;
                  return isEmpty(sSucursal) ? (
                    <></>
                  ) : (
                    <Stack key={suc.description}>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          sx={{
                            color: "#2196f3",
                            textAlign: "left",
                          }}
                          fontSize={11}
                        >
                          Sucursal:
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "left",
                          }}
                          fontSize={11}
                        >
                          {`${suc.description}`}
                        </Typography>
                      </Stack>

                      <Divider />

                      <TableContainer>
                        <Table aria-label="simple table" size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 13 }}
                              >
                                #
                              </TableCell>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 13 }}
                              >
                                Fecha
                              </TableCell>
                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 13 }}
                              >
                                Descripcion
                              </TableCell>

                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 13 }}
                              >
                                Cuenta
                              </TableCell>

                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 13 }}
                              >
                                Monto
                              </TableCell>

                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 13 }}
                              >
                                Realizado por
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sSucursal.map((row) => {
                              momtoTotal += row.monto;
                              montoTotalConsol += row.monto;
                              return (
                                <TableRow key={row.id}>
                                  <TableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {row.id}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {moment(row.fecha).format("L")}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {row.description}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {row.payWith}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {new Intl.NumberFormat("es-NI", {
                                      style: "currency",
                                      currency: "NIO",
                                    }).format(row.monto)}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {`${row.realizadoPor.split(" ")[0]}  ${
                                      row.realizadoPor.split(" ")[1]
                                    }`}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <hr />
                      <Stack
                        direction="row"
                        flex="row"
                        justifyContent="space-around"
                      >
                        <Stack textAlign="center">
                          <Typography
                            style={{
                              fontWeight: "bold",
                              color: "#03a9f4",
                              fontSize: 11,
                            }}
                          >
                            Total de Transacciones
                          </Typography>
                          <Typography style={{ fontSize: 11 }}>
                            {sSucursal.length}
                          </Typography>
                        </Stack>

                        <Stack textAlign="center">
                          <Typography
                            style={{
                              fontWeight: "bold",
                              color: "#03a9f4",
                              fontSize: 11,
                            }}
                          >
                            Monto Total
                          </Typography>
                          <Typography style={{ fontSize: 11 }}>
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(momtoTotal)}
                          </Typography>
                        </Stack>
                      </Stack>
                      <hr />
                    </Stack>
                  );
                })}
              </>
            )}

            {selectedSucursal === "t" ? (
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "center",
                  }}
                >
                  Consolidado
                </Typography>
                <hr />
                <Stack direction="row" flex="row" justifyContent="space-around">
                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      Consolidado de Transacciones
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {data.length}
                    </Typography>
                  </Stack>

                  <Stack textAlign="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#03a9f4",
                        fontSize: 11,
                      }}
                    >
                      Consolidado Monto Total
                    </Typography>
                    <Typography style={{ fontSize: 11 }}>
                      {new Intl.NumberFormat("es-NI", {
                        style: "currency",
                        currency: "NIO",
                      }).format(montoTotalConsol)}
                    </Typography>
                  </Stack>
                </Stack>
                <hr />
              </Stack>
            ) : (
              <></>
            )}
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default ReporteGastosHistory;
