import React, { useContext, useState } from 'react';
import {
	Paper,
	Box,
	Tabs,
	Tab,
	Divider,
	Typography,
	Container,
	Stack,
	Button
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCalendarDay,
	faChevronCircleLeft,
	faCreditCard,
	faDonate,
	faHouseLaptop
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { DataContext } from '../../../context/DataContext';
import { useNavigate, useParams } from 'react-router-dom';

import { getRuta } from '../../../helpers/Helpers';
import CreditDetails from './CreditDetails';
import CreditGarantias from './CreditGarantias';
import CreditPayment from './CreditPayment';
import CreditAbono from './CreditAbono';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

const CreditDetailsContainer = () => {
	const { access } = useContext(DataContext);
	let ruta = getRuta();
	let navigate = useNavigate();
	const { id } = useParams();
	const [value, setValue] = useState(0);
	const [clientName, setClientName] = useState('');
	const [dataCredit, setDataCredit] = useState('');
	const [isNominal, setIsNominal] = useState(false);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div>
			<Container>
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					<Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={1}>
						<Button
							onClick={() => {
								navigate(`${ruta}/credits`);
							}}
							style={{
								color: '#2979ff',
								borderColor: '#2979ff'
							}}
							variant="outlined"
						>
							<FontAwesomeIcon
								style={{ marginRight: 10, fontSize: 20 }}
								icon={faChevronCircleLeft}
							/>
							Regresar
						</Button>

						<Stack direction={'row'} spacing={1} style={{ flexGrow: 1 }}>
							<Typography
								color={'#00a152'}
								variant="h6"
								fontWeight={'bold'}
								className="d-none d-sm-block"
							>
								Cliente:
							</Typography>
							<Typography variant="h6" fontWeight={'bold'}>
								{clientName}
							</Typography>
						</Stack>

						<Typography color={'#ffc400'} className="d-none d-sm-block">
							{`Estadistica: ${(dataCredit ? dataCredit.promedio : 0).toFixed(2)}`}
						</Typography>
					</Stack>

					<Divider style={{ marginTop: 10 }} />

					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="icon label tabs example"
						centered
					>
						<Tab
							icon={<FontAwesomeIcon icon={faCreditCard} style={{ fontSize: 20 }} />}
							label="Credito"
							{...a11yProps(0)}
							style={{ fontSize: 12 }}
						/>

						<Tab
							icon={<FontAwesomeIcon icon={faHouseLaptop} style={{ fontSize: 20 }} />}
							label="Garantias"
							{...a11yProps(0)}
							style={{ fontSize: 12 }}
						/>

						<Tab
							icon={<FontAwesomeIcon icon={faCalendarDay} style={{ fontSize: 20 }} />}
							label="Plan de Pagos"
							{...a11yProps(0)}
							style={{ fontSize: 12 }}
						/>

						<Tab
							icon={<FontAwesomeIcon icon={faDonate} style={{ fontSize: 20 }} />}
							label="Abonos"
							{...a11yProps(0)}
							style={{ fontSize: 12 }}
						/>
					</Tabs>

					<Divider style={{ marginTop: 10 }} />
					<TabPanel value={value} index={0}>
						<CreditDetails
							id={id}
							setClientName={setClientName}
							isNominal={isNominal}
							setIsNominal={setIsNominal}
							setDataCredit={setDataCredit}
						/>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<CreditGarantias id={id} />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<CreditPayment
							id={id}
							clientName={clientName}
							isNominal={isNominal}
							dataCredit={dataCredit}
						/>
					</TabPanel>
					<TabPanel value={value} index={3}>
						<CreditAbono id={id} isNominal={isNominal} />
					</TabPanel>
				</Paper>
			</Container>
		</div>
	);
};

export default CreditDetailsContainer;
