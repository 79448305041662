import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Container,
	IconButton,
	Paper,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Table,
	Typography,
	Stack,
	TextField,
	InputAdornment,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSearch,
	faCirclePlus,
	faTrashAlt,
	faExternalLink
} from '@fortawesome/free-solid-svg-icons';
import { isEmpty, isUndefined } from 'lodash';

import { getRuta, isAccess, simpleMessage } from '../../helpers/Helpers';
import { getToken } from '../../api/Account';
import { DataContext } from '../../context/DataContext';
import { deleteClientAsync, getClientListAsync } from '../../api/Clients';
import NoData from '../../components/NoData';
import { PaginationComponent } from '../../components/PaginationComponent';
import Swal from 'sweetalert2';
import { getSucursalByUserAsync } from '../../api/Users';

const ClientList = () => {
	const [clientList, setClientList] = useState([]);
	const [localReload, setLocalReload] = useState(false);

	const [sucursalList, setSucursalList] = useState([]);

	const { setIsLoading, setIsUnautorized, access, selectedSucursal, setSelectedSucursal } =
		useContext(DataContext);

	let ruta = getRuta();
	const token = getToken();
	let navigate = useNavigate();

	const [searchTerm, setSearchTerm] = useState('');

	const withSearch = clientList.filter((val) => {
		if (searchTerm === '') {
			return val;
		} else if (
			val.id.toString().includes(searchTerm) ||
			val.fullName.toString().includes(searchTerm) ||
			val.identification.toString().includes(searchTerm)
		) {
			return val;
		}
	});

	useEffect(() => {
		getSucursals();
	}, [localReload]);

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsperPage] = useState(10);
	const indexLast = currentPage * itemsperPage;
	const indexFirst = indexLast - itemsperPage;
	const currentItem = withSearch.slice(indexFirst, indexLast);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const onChangeSearch = (val) => {
		setCurrentPage(1);
		setSearchTerm(val);
		paginate(1);
	};

	const deleteClient = async (item) => {
		Swal.fire({
			title: '¿Estas Seguro?',
			text: 'No podras revertir esto...!',
			icon: 'warning',
			showCancelButton: true,

			confirmButtonColor: '#2979ff',
			cancelButtonColor: '#f50057',

			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar',
			customClass: {
				popup: 'border'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				(async () => {
					setIsLoading(true);
					const result = await deleteClientAsync(token, item.id);
					if (!result.statusResponse) {
						setIsLoading(false);
						if (result.error === 'Unauthorized') {
							setIsUnautorized(true);
							return;
						}
						simpleMessage(result.error, 'error');
						return;
					}

					setIsLoading(false);
					setLocalReload(!localReload);

					Swal.fire('Eliminado!', 'Cliente Eliminado.', 'success');
				})();
			}
		});
	};

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(result.data.result);
		if (selectedSucursal === 0 || isUndefined(selectedSucursal)) {
			setSelectedSucursal(result.data.result[0].id);
			await getClients(result.data.result[0].id);
			return;
		}
		await getClients(selectedSucursal);
	};

	const onChangeSucursal = (value) => {
		setSelectedSucursal(value);
		getClients(value);
	};

	const getClients = async (sucursal) => {
		setIsLoading(true);
		const result = await getClientListAsync(token, sucursal);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setIsLoading(false);
		setClientList(result.data.result);
	};

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					<Stack direction={'row'} justifyContent="space-between">
						<Typography color={'#00a152'} variant="h4" fontWeight={'bold'}>
							Lista de Clientes
						</Typography>

						<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
							<FormControl style={{ textAlign: 'left', width: 200 }}>
								<InputLabel id="demo-simple-select-standard-label">
									Sucursal
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => onChangeSucursal(e.target.value)}
									value={selectedSucursal}
									label="Sucursal"
								>
									{sucursalList.map((item) => {
										return (
											<MenuItem key={item.id} value={item.id}>
												{item.description}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							{isAccess(access, 'CLIENTS CREATE') ? (
								<Button
									onClick={() => {
										navigate(`${ruta}/addclient`);
									}}
									startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
									variant="outlined"
									style={{
										color: '#00a152',
										borderColor: '#00a152'
									}}
								>
									Agregar Cliente
								</Button>
							) : (
								<></>
							)}
						</Stack>
					</Stack>

					<hr />

					<TextField
						style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
						fullWidth
						onChange={(e) => onChangeSearch(e.target.value.toUpperCase())}
						value={searchTerm}
						label={'Buscar Cliente'}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton>
										<FontAwesomeIcon
											icon={faSearch}
											style={{ color: '#1769aa' }}
										/>
									</IconButton>
								</InputAdornment>
							)
						}}
					/>

					{isEmpty(currentItem) ? (
						<NoData />
					) : (
						<Stack spacing={2}>
							<TableContainer>
								<Table aria-label="simple table" size="small">
									<TableHead>
										<TableRow>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												#
											</TableCell>
											<TableCell
												align="left"
												className="text-primary fw-bold"
											>
												Nombre Completo
											</TableCell>
											<TableCell
												align="left"
												className="text-primary fw-bold"
											>
												Cédula
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Teléfono
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Creditos Activos
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Ciclos
											</TableCell>
											{isAccess(access, 'CLIENTS UPDATE') ||
											isAccess(access, 'CLIENTS DELETE') ? (
												<TableCell
													align="center"
													className="text-primary fw-bold"
												>
													Acciones
												</TableCell>
											) : (
												<></>
											)}
										</TableRow>
									</TableHead>
									<TableBody>
										{currentItem.map((row) => {
											return (
												<TableRow key={row.id}>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{row.id}
													</TableCell>
													<TableCell align="left">
														{row.fullName}
													</TableCell>
													<TableCell align="left">
														{row.identification}
													</TableCell>
													<TableCell align="center">
														{row.phone}
													</TableCell>
													<TableCell align="center">
														{row.activeCredits}
													</TableCell>
													<TableCell align="center">
														{row.ciclos}
													</TableCell>
													{isAccess(access, 'CLIENTS UPDATE') ||
													isAccess(access, 'CLIENTS DELETE') ? (
														<TableCell align="center">
															<Stack
																direction={'row'}
																spacing={1}
																justifyContent="center"
															>
																{isAccess(
																	access,
																	'CLIENTS UPDATE'
																) ? (
																	<IconButton
																		style={{
																			color: '#ffc400'
																		}}
																		onClick={() => {
																			navigate(
																				`${ruta}/client/${row.id}`
																			);
																		}}
																	>
																		<FontAwesomeIcon
																			icon={faExternalLink}
																		/>
																	</IconButton>
																) : (
																	<></>
																)}
																{isAccess(
																	access,
																	'CLIENTS DELETE'
																) ? (
																	<IconButton
																		style={{
																			color: '#f50057',
																			width: 40,
																			height: 40
																		}}
																		onClick={() =>
																			deleteClient(row)
																		}
																	>
																		<FontAwesomeIcon
																			icon={faTrashAlt}
																		/>
																	</IconButton>
																) : (
																	<></>
																)}
															</Stack>
														</TableCell>
													) : (
														<></>
													)}
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>

							<PaginationComponent
								data={withSearch}
								paginate={paginate}
								itemsperPage={itemsperPage}
							/>
						</Stack>
					)}
				</Paper>
			</Container>
		</div>
	);
};

export default ClientList;
