import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Paper,
  Divider,
} from "@mui/material";
import { isEmpty, words } from "lodash";

import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";

import { getRol, getToken, getUser } from "../../../api/Account";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import NoData from "../../../components/NoData";
import { PrintReport } from "../../../components/PrintReport";
import moment from "moment";
import { getSucursalByUserAsync } from "../../../api/Users";
import { simpleMessage } from "../../../helpers/Helpers";
import { getBalanceGralAsync } from "../../../api/AdmonReport";

const ReporteBalanceGeneral = () => {
  const [data, setData] = useState([]);
  const [sucursalName, setSucursalName] = useState("");

  const compRef = useRef();
  const { selectedSucursal, fechaHasta, selectedGestor } = useParams();

  const { setIsLoading, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const token = getToken();
  const user = getUser();

  useEffect(() => {
    (async () => {
      let sucursals = await getSucursals();

      if (selectedSucursal === "t") {
        setSucursalName("Todas");
      } else {
        let name = sucursals.filter(
          (item) => item.id.toString() === selectedSucursal
        );
        setSucursalName(name[0].description);
      }

      const data = {
        hasta: fechaHasta.toString(),
        gestorId: selectedGestor === "t" ? 0 : selectedGestor,
        sucursalId: selectedSucursal === "t" ? 0 : selectedSucursal,
      };

      setIsLoading(true);
      const result = await getBalanceGralAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setData(result.data.result);
    })();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    return result.data.result;
  };

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 8mm; }
`;

  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Typography
            sx={{
              color: "#2196f3",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {slogan}
          </Typography>

          <Typography
            sx={{
              color: "#2196f3",
              textAlign: "center",
              fontWeight: "bold",
            }}
            variant="h6"
            component="div"
          >
            Balance General
          </Typography>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>

                <Stack direction={"row"} spacing={1}>
                  <Typography fontSize={11} textAlign="left">
                    Al:
                  </Typography>

                  <Typography fontSize={11} textAlign="left">
                    {moment(fechaHasta).format("LL")}
                  </Typography>
                </Stack>
              </Stack>

              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          <ReactToPrint
            trigger={() => {
              return (
                <IconButton
                  variant="outlined"
                  style={{ position: "fixed", right: 20, top: 75 }}
                >
                  <PrintRoundedIcon
                    style={{ fontSize: 35, color: "#2979ff", width: 35 }}
                  />
                </IconButton>
              );
            }}
            content={() => compRef.current}
            pageStyle={pageStyle}
          />
        </Stack>

        <Container maxWidth="lg" sx={{ textAlign: "center" }}>
          <hr />

          {isEmpty(data) ? (
            <NoData />
          ) : (
            <>
              <Paper
                style={{
                  padding: 20,
                }}
              >
                {data.map((level1, indexl1) => {
                  console.log(level1);
                  const { cuentasLevel2 } = level1;
                  let capital = data[0].saldo - data[1].saldo;
                  return (
                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableBody>
                          <TableRow
                            key={level1.descripcion}
                            sx={{ "& td": { border: 0 } }}
                          >
                            <TableCell colSpan={5}>
                              <Typography
                                sx={{
                                  textAlign: "left",
                                }}
                                fontSize={15}
                              >
                                {level1.descripcion}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow key={1} sx={{ "& td": { border: 0 } }}>
                            <TableCell colSpan={5}>
                              {cuentasLevel2.map((level2, indexl2) => {
                                const { cuentasMayores } = level2;
                                return (
                                  <TableContainer>
                                    <Table
                                      aria-label="simple table"
                                      size="small"
                                    >
                                      <TableBody>
                                        <TableRow key={level2.descripcion}>
                                          <TableCell
                                            colSpan={5}
                                            style={{
                                              borderBottomWidth: 0,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textAlign: "left",
                                                // marginLeft: 2,
                                              }}
                                              fontSize={13}
                                            >
                                              {level2.descripcion}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>

                                        <TableRow key={2}>
                                          <TableCell colSpan={5}>
                                            {cuentasMayores.map(
                                              (mayor, index) => {
                                                return (
                                                  <TableContainer>
                                                    <Table
                                                      aria-label="simple table"
                                                      size="small"
                                                    >
                                                      <TableBody>
                                                        <TableRow
                                                          key={
                                                            mayor.descripcion
                                                          }
                                                        >
                                                          <TableCell
                                                            align="left"
                                                            width={300}
                                                            style={{
                                                              borderBottomWidth: 0,
                                                            }}
                                                          >
                                                            <Typography
                                                              sx={{
                                                                textAlign:
                                                                  "left",
                                                                // marginLeft: 4,
                                                              }}
                                                              fontSize={11}
                                                            >
                                                              {
                                                                mayor.descripcion
                                                              }
                                                            </Typography>
                                                          </TableCell>
                                                          <TableCell
                                                            style={{
                                                              borderBottomWidth: 0,
                                                            }}
                                                            width={200}
                                                          />
                                                          <TableCell
                                                            width={200}
                                                            align="center"
                                                            style={{
                                                              fontSize: 11,
                                                              borderBottomWidth:
                                                                index ===
                                                                cuentasMayores.length -
                                                                  1
                                                                  ? 3
                                                                  : 0,
                                                            }}
                                                          >
                                                            {new Intl.NumberFormat(
                                                              "es-NI",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: "NIO",
                                                              }
                                                            ).format(
                                                              mayor.saldo
                                                            )}
                                                          </TableCell>

                                                          <TableCell
                                                            style={{
                                                              borderBottomWidth: 0,
                                                            }}
                                                            width={200}
                                                          />
                                                          <TableCell
                                                            style={{
                                                              borderBottomWidth: 0,
                                                            }}
                                                            width={200}
                                                          />
                                                        </TableRow>
                                                      </TableBody>
                                                    </Table>
                                                  </TableContainer>
                                                );
                                              }
                                            )}
                                          </TableCell>
                                        </TableRow>

                                        <TableRow key={"totalL2"}>
                                          <TableCell
                                            width={300}
                                            style={{
                                              borderBottomWidth: 0,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textAlign: "left",
                                                // marginLeft: 2,
                                              }}
                                              fontSize={13}
                                            >
                                              {`TOTAL ${level2.descripcion}`}
                                            </Typography>
                                          </TableCell>

                                          <TableCell
                                            width={200}
                                            style={{
                                              borderBottomWidth: 0,
                                            }}
                                          />

                                          <TableCell
                                            width={200}
                                            style={{
                                              borderBottomWidth: 0,
                                            }}
                                          />
                                          <TableCell
                                            width={200}
                                            align="center"
                                            style={{
                                              fontSize: 13,
                                              borderBottomWidth:
                                                indexl2 ===
                                                cuentasLevel2.length - 1
                                                  ? 3
                                                  : 0,
                                            }}
                                          >
                                            {new Intl.NumberFormat("es-NI", {
                                              style: "currency",
                                              currency: "NIO",
                                            }).format(level2.saldo)}
                                          </TableCell>
                                          <TableCell
                                            width={200}
                                            style={{
                                              borderBottomWidth: 0,
                                            }}
                                          />
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                );
                              })}
                            </TableCell>
                          </TableRow>

                          <TableRow
                            key={"totalL1"}
                            sx={{ "& td": { border: 0 } }}
                          >
                            <TableCell width={300}>
                              <Typography
                                sx={{
                                  textAlign: "left",
                                }}
                                fontSize={13}
                              >
                                {`TOTAL ${level1.descripcion}`}
                              </Typography>
                            </TableCell>
                            <TableCell width={200} />
                            <TableCell width={200} />
                            <TableCell width={200} />
                            <TableCell
                              width={200}
                              align="center"
                              style={{
                                fontSize: 11,
                                borderBottomWidth:
                                  indexl1 === data.length - 1 ? 3 : 0,
                              }}
                            >
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(level1.saldo)}
                            </TableCell>
                          </TableRow>

                          <TableRow
                            key={"totalL1"}
                            sx={{ "& td": { border: 0 } }}
                          >
                            <TableCell width={300}>
                              <Typography
                                sx={{
                                  textAlign: "left",
                                }}
                                fontSize={16}
                              >
                                CAPITAL CONTABLE
                              </Typography>
                            </TableCell>
                            <TableCell width={200} />
                            <TableCell width={200} />
                            <TableCell width={200} />
                            <TableCell
                              width={200}
                              align="center"
                              style={{
                                fontSize: 13,
                                borderBottomWidth: 5,
                              }}
                            >
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(capital)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  );
                })}
              </Paper>
            </>
          )}
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte Estado de Resultado"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <hr />

            <Container maxWidth={false} sx={{ textAlign: "center" }}>
              <Stack direction={"row"} justifyContent="space-between">
                <Stack>
                  <Typography
                    sx={{
                      color: "#2196f3",
                      textAlign: "left",
                    }}
                    fontSize={11}
                  >
                    Parametros:
                  </Typography>
                  <Typography fontSize={11} textAlign="left">
                    {`Sucursal: ${
                      selectedSucursal === "t" ? "Todas" : sucursalName
                    }`}
                  </Typography>

                  <Stack direction={"row"} spacing={1}>
                    <Typography fontSize={11} textAlign="left">
                      Al:
                    </Typography>

                    <Typography fontSize={11} textAlign="left">
                      {moment(fechaHasta).format("LL")}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack>
                  <Typography
                    sx={{
                      color: "#2196f3",
                      textAlign: "left",
                    }}
                    fontSize={11}
                  >
                    Usuario:
                  </Typography>
                  <Typography fontSize={11} textAlign="left">
                    {user}
                  </Typography>

                  <Typography fontSize={11} textAlign="left">
                    {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                  </Typography>
                </Stack>
              </Stack>
            </Container>
          </Container>

          <Container maxWidth="lg" sx={{ textAlign: "center" }}>
            <hr />

            {isEmpty(data) ? (
              <NoData />
            ) : (
              <>
                {data.map((level1, indexl1) => {
                  const { cuentasLevel2 } = level1;
                  let capital = data[0].saldo - data[1].saldo;
                  return (
                    <TableContainer>
                      <Table aria-label="simple table" size="small">
                        <TableBody>
                          <TableRow
                            key={level1.descripcion}
                            sx={{ "& td": { border: 0 } }}
                          >
                            <TableCell colSpan={5} className="text-dark">
                              <Typography
                                sx={{
                                  textAlign: "left",
                                }}
                                fontSize={15}
                              >
                                {level1.descripcion}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow key={1} sx={{ "& td": { border: 0 } }}>
                            <TableCell colSpan={5} className="text-dark">
                              {cuentasLevel2.map((level2, indexl2) => {
                                const { cuentasMayores } = level2;
                                return (
                                  <TableContainer>
                                    <Table
                                      aria-label="simple table"
                                      size="small"
                                    >
                                      <TableBody>
                                        <TableRow key={level2.descripcion}>
                                          <TableCell
                                            className="text-dark"
                                            colSpan={5}
                                            style={{
                                              borderBottomWidth: 0,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textAlign: "left",
                                                // marginLeft: 2,
                                              }}
                                              fontSize={13}
                                            >
                                              {level2.descripcion}
                                            </Typography>
                                          </TableCell>
                                        </TableRow>

                                        <TableRow key={2}>
                                          <TableCell
                                            colSpan={5}
                                            className="text-dark"
                                          >
                                            {cuentasMayores.map(
                                              (mayor, index) => {
                                                return (
                                                  <TableContainer>
                                                    <Table
                                                      aria-label="simple table"
                                                      size="small"
                                                    >
                                                      <TableBody>
                                                        <TableRow
                                                          key={
                                                            mayor.descripcion
                                                          }
                                                        >
                                                          <TableCell
                                                            className="text-dark"
                                                            align="left"
                                                            width={300}
                                                            style={{
                                                              borderBottomWidth: 0,
                                                            }}
                                                          >
                                                            <Typography
                                                              sx={{
                                                                textAlign:
                                                                  "left",
                                                                // marginLeft: 4,
                                                              }}
                                                              fontSize={11}
                                                            >
                                                              {
                                                                mayor.descripcion
                                                              }
                                                            </Typography>
                                                          </TableCell>
                                                          <TableCell
                                                            className="text-dark"
                                                            style={{
                                                              borderBottomWidth: 0,
                                                            }}
                                                            width={200}
                                                          />
                                                          <TableCell
                                                            className="text-dark"
                                                            width={200}
                                                            align="center"
                                                            style={{
                                                              fontSize: 11,
                                                              borderBottomWidth:
                                                                index ===
                                                                cuentasMayores.length -
                                                                  1
                                                                  ? 3
                                                                  : 0,
                                                            }}
                                                          >
                                                            {new Intl.NumberFormat(
                                                              "es-NI",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency: "NIO",
                                                              }
                                                            ).format(
                                                              mayor.saldo
                                                            )}
                                                          </TableCell>

                                                          <TableCell
                                                            style={{
                                                              borderBottomWidth: 0,
                                                            }}
                                                            width={200}
                                                          />
                                                          <TableCell
                                                            style={{
                                                              borderBottomWidth: 0,
                                                            }}
                                                            width={200}
                                                          />
                                                        </TableRow>
                                                      </TableBody>
                                                    </Table>
                                                  </TableContainer>
                                                );
                                              }
                                            )}
                                          </TableCell>
                                        </TableRow>

                                        <TableRow key={"totalL2"}>
                                          <TableCell
                                            className="text-dark"
                                            width={300}
                                            style={{
                                              borderBottomWidth: 0,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textAlign: "left",
                                                // marginLeft: 2,
                                              }}
                                              fontSize={13}
                                            >
                                              {`TOTAL ${level2.descripcion}`}
                                            </Typography>
                                          </TableCell>

                                          <TableCell
                                            width={200}
                                            style={{
                                              borderBottomWidth: 0,
                                            }}
                                          />

                                          <TableCell
                                            width={200}
                                            style={{
                                              borderBottomWidth: 0,
                                            }}
                                          />
                                          <TableCell
                                            className="text-dark"
                                            width={200}
                                            align="center"
                                            style={{
                                              fontSize: 13,
                                              borderBottomWidth:
                                                indexl2 ===
                                                cuentasLevel2.length - 1
                                                  ? 3
                                                  : 0,
                                            }}
                                          >
                                            {new Intl.NumberFormat("es-NI", {
                                              style: "currency",
                                              currency: "NIO",
                                            }).format(level2.saldo)}
                                          </TableCell>
                                          <TableCell
                                            width={200}
                                            style={{
                                              borderBottomWidth: 0,
                                            }}
                                          />
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                );
                              })}
                            </TableCell>
                          </TableRow>

                          <TableRow
                            key={"totalL1"}
                            sx={{ "& td": { border: 0 } }}
                          >
                            <TableCell width={300}  className="text-dark">
                              <Typography
                                sx={{
                                  textAlign: "left",
                                }}
                                fontSize={13}
                              >
                                {`TOTAL ${level1.descripcion}`}
                              </Typography>
                            </TableCell>
                            <TableCell width={200} />
                            <TableCell width={200} />
                            <TableCell width={200} />
                            <TableCell
                             className="text-dark"
                              width={200}
                              align="center"
                              style={{
                                fontSize: 11,
                                borderBottomWidth:
                                  indexl1 === data.length - 1 ? 3 : 0,
                              }}
                            >
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(level1.saldo)}
                            </TableCell>
                          </TableRow>

                          <TableRow
                            key={"totalL1"}
                            sx={{ "& td": { border: 0 } }}
                          >
                            <TableCell width={300}  className="text-dark">
                              <Typography
                                sx={{
                                  textAlign: "left",
                                }}
                                fontSize={16}
                              >
                                CAPITAL CONTABLE
                              </Typography>
                            </TableCell>
                            <TableCell width={200} />
                            <TableCell width={200} />
                            <TableCell width={200} />
                            <TableCell
                             className="text-dark"
                              width={200}
                              align="center"
                              style={{
                                fontSize: 13,
                                borderBottomWidth: 5,
                              }}
                            >
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(capital)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  );
                })}
              </>
            )}
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default ReporteBalanceGeneral;
