import axios from "axios";
import { errorResponse } from "../helpers/Helpers";
const { REACT_APP_PRODURL, REACT_APP_URL } = process.env;
let Api = "";

if (process.env.NODE_ENV === "production") {
  Api = `${REACT_APP_PRODURL}contabilidad`;
} else {
  Api = `${REACT_APP_URL}contabilidad`;
}

export const getCatalogoCuentasListAsync = async (token) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetCatalogoCuentas";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getCuentasGastoListAsync = async (token) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetCuentasGasto";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getTipoGastoAsync = async (token) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetTipoGastos";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getTipoFlujoAsync = async (token) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetTipoFlujos";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getGrupoCuentasListAsync = async (token) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetGrupoCuentas";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getCodeStructureListAsync = async (token) => {
  const result = { statusResponse: true, data: [], error: null };
  let url = Api + "/GetCodeStructure";
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(url).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const addCuentaContableAsync = async (token, data) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/AddCuentaContable`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service, data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const updateCuentaContableAsync = async (token, data) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/UpdateCuentaContable`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service, data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getGastosAsync = async (token, data) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetGastos`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service, data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getAsientosContableAsync = async (token, data) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetLibroDiario`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service, data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

// export const getConceptoGastosAsync = async (token, id) => {
//   const result = { statusResponse: true, data: [], error: null };
//   let service = `${Api}/GetConceptoGastos/`;
//   const authAxios = axios.create({
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
//   try {
//     await authAxios.get(service + id).then((resp) => {
//       if (resp.status <= 200 && resp.status >= 299) {
//         result.statusResponse = false;
//         result.error = resp.title;
//       } else {
//         result.statusResponse = true;
//         result.data = resp.data;
//       }
//     });
//   } catch (error) {
//     result.statusResponse = false;
//     result.error = errorResponse(error.response);
//   }
//   return result;
// };

// export const addConceptoGastoAsync = async (token, data) => {
//   const result = { statusResponse: true, data: [], error: null };
//   let service = `${Api}/AddConceptoGastoId`;
//   const authAxios = axios.create({
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
//   try {
//     await authAxios.post(service, data).then((resp) => {
//       if (resp.status <= 200 && resp.status >= 299) {
//         result.statusResponse = false;
//         result.error = resp.title;
//       } else {
//         result.statusResponse = true;
//         result.data = resp.data;
//       }
//     });
//   } catch (error) {
//     result.statusResponse = false;
//     result.error = errorResponse(error.response);
//   }
//   return result;
// };

// export const updateConceptoGastoAsync = async (token, data) => {
//   const result = { statusResponse: true, data: [], error: null };
//   let service = `${Api}/UpdateConceptoGasto`;
//   const authAxios = axios.create({
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
//   try {
//     await authAxios.post(service, data).then((resp) => {
//       if (resp.status <= 200 && resp.status >= 299) {
//         result.statusResponse = false;
//         result.error = resp.title;
//       } else {
//         result.statusResponse = true;
//         result.data = resp.data;
//       }
//     });
//   } catch (error) {
//     result.statusResponse = false;
//     result.error = errorResponse(error.response);
//   }
//   return result;
// };

export const isInicioContableAsync = async (token) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/IsInicioAsientos`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getSucursalWitoutAsientosAsync = async (token) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetSucursalWitoutAsientos`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const aperturaContabilidadAsync = async (token, data) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/AperturarContabilidad`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service, data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getCuentasGastoLevel4Async = async (token) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetCuentasGastoLevel4`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getCuentasGastoLevel5Async = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetCuentasGastoLevel5/`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service + id).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const addAsientoContableAsync = async (token, data) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/AddAsientoContable`;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.post(service, data).then((resp) => {
      if (resp.status <= 200 && resp.status >= 299) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};
