import React, { useContext } from "react";

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Stack,
} from "@mui/material";
import moment from "moment";

import { DataContext } from "../../../context/DataContext";
import { conv2Letras } from "../../../helpers/n2Letras";

const PrintGasto = React.forwardRef((props, ref) => {
  const { title, slogan, icon } = useContext(DataContext);
  const {
    id,
    description,
    entradas,
    fecha,
    payWith,
    realizadoPor,
    sucursal,
    beneficiario,
  } = props.gasto;

  const getPageMargins = () => {
    return `body {
          color:black;
        }`;
  };

  return (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <>
        <Stack direction="row" justifyContent="space-between">
          <img src={icon} style={{ width: 70, height: 70 }} />
          <Stack>
            <Typography variant="h5" color="#212121" textAlign={"center"}>
              {title}
            </Typography>
            <Typography color="#212121" textAlign={"center"}>
              {slogan}
            </Typography>
            <Typography variant="h6" color="#212121" textAlign={"center"}>
              {`${sucursal} - NICARAGUA`}
            </Typography>
          </Stack>
          <img src={icon} style={{ width: 70, height: 70 }} />
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          paddingLeft={20}
          paddingRight={10}
        >
          <Stack />
          <Stack>
            <Typography variant="h6" color="#212121" textAlign={"center"}>
              Recibo
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h6" color="#f50057" textAlign="left">
              {`N° ${id}`}
            </Typography>
            <Typography variant="h6" color="#f50057" textAlign="left">
              {`Valor: ${entradas}`}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <Typography textAlign={"left"} color="#212121">
            NOMBRE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${beneficiario}`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            LA CANTIDAD DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${conv2Letras(entradas)}`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            EN CONCEOTO DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${description}`}
            </Typography>
          </Typography>

          <Typography textAlign={"right"}>
            {`fecha: ${moment(fecha).format("dddd,")} ${moment(fecha).format(
              "D"
            )} de ${moment(fecha).format("MMMM")} de ${moment(fecha).format(
              "YYYY"
            )}`}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" marginTop={15}>
          <Stack>
            <Typography color="#212121" textAlign={"center"}>
              --------------------------------------------
            </Typography>
            <Typography color="#212121" textAlign={"center"}>
              ENTREGUE CONFORME
            </Typography>
          </Stack>
          <Stack>
            <Typography color="#212121" textAlign={"center"}>
              --------------------------------------------
            </Typography>
            <Typography color="#212121" textAlign={"center"}>
              RECIBI CONFORME
            </Typography>
          </Stack>
        </Stack>

        <hr />

        <Stack direction="row" justifyContent="space-between">
          <img src={icon} style={{ width: 70, height: 70 }} />
          <Stack>
            <Typography variant="h5" color="#212121" textAlign={"center"}>
              {title}
            </Typography>
            <Typography color="#212121" textAlign={"center"}>
              {slogan}
            </Typography>
            <Typography variant="h6" color="#212121" textAlign={"center"}>
              {`${sucursal} - NICARAGUA`}
            </Typography>
          </Stack>
          <img src={icon} style={{ width: 70, height: 70 }} />
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          paddingLeft={20}
          paddingRight={10}
        >
          <Stack />
          <Stack>
            <Typography variant="h6" color="#212121" textAlign={"center"}>
              Recibo
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="h6" color="#f50057" textAlign="left">
              {`N° ${id}`}
            </Typography>
            <Typography variant="h6" color="#f50057" textAlign="left">
              {`Valor: ${entradas}`}
            </Typography>
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <Typography textAlign={"left"} color="#212121">
            NOMBRE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${beneficiario}`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            LA CANTIDAD DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${conv2Letras(entradas)}`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            EN CONCEOTO DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${description}`}
            </Typography>
          </Typography>

          <Typography textAlign={"right"}>
            {`fecha: ${moment(fecha).format("dddd,")} ${moment(fecha).format(
              "D"
            )} de ${moment(fecha).format("MMMM")} de ${moment(fecha).format(
              "YYYY"
            )}`}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" marginTop={15}>
          <Stack>
            <Typography color="#212121" textAlign={"center"}>
              --------------------------------------------
            </Typography>
            <Typography color="#212121" textAlign={"center"}>
              ENTREGUE CONFORME
            </Typography>
          </Stack>
          <Stack>
            <Typography color="#212121" textAlign={"center"}>
              --------------------------------------------
            </Typography>
            <Typography color="#212121" textAlign={"center"}>
              RECIBI CONFORME
            </Typography>
          </Stack>
        </Stack>
      </>
    </div>
  );
});

export default PrintGasto;
