import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Container,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Paper,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { simpleMessage } from "../../../helpers/Helpers";
import {
  aperturaContabilidadAsync,
  getSucursalWitoutAsientosAsync,
} from "../../../api/Contabilidad";
import { getToken } from "../../../api/Account";
import { DataContext } from "../../../context/DataContext";

const AperturaContable = ({ localReload, setLocalReload }) => {
  const [cuentaSelected, setCuentaSelected] = useState(1);
  const [montoApertura, setMontoApertura] = useState("");
  const [referencia, setReferencia] = useState("");

  const [sucursalList, setSucursalList] = useState([]);
  const [selectedSucursal, setSelectedSucursal] = useState("");

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  const montoMov = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString()) || value === "") {
      setMontoApertura(value);
      return;
    }
  };

  const saveChangesAsync = async () => {
    if (montoApertura.length === 0) {
      simpleMessage("Ingrese el monto de apertura...", "error");
      return;
    }
    if (referencia.length === 0) {
      simpleMessage("Ingrese el concepto de apertura...", "error");
      return;
    }
    const data = {
      cuentaId: cuentaSelected === 5 ? 1 : 3,
      monto: montoApertura,
      referencia,
      sucursalId: selectedSucursal,
    };

    setIsLoading(true);
    const result = await aperturaContabilidadAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Apertura contable lista...!", "success");
    setLocalReload(!localReload);
  };

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalWitoutAsientosAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(result.data.result);
    setSelectedSucursal(result.data.result[0].id);
    return result.data.result[0].id;
  };

  useEffect(() => {
    getSucursals();
  }, []);

  return (
    <div>
      <Container maxWidth={500}>
        <Paper style={{ padding: 20 }}>
          <Typography
            textAlign={"center"}
            color={"#00a152"}
            variant="h6"
            fontWeight={"bold"}
          >
            Aperturar Contabilidad
          </Typography>

          <hr />

          <FormControl fullWidth style={{ textAlign: "left" }}>
            <InputLabel id="demo-simple-select-standard-label">
              Sucursal
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={(e) => setSelectedSucursal(e.target.value)}
              value={selectedSucursal}
              label="Sucursal"
            >
              {sucursalList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl fullWidth style={{ textAlign: "left", marginTop: 20 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Seleccione una cuenta
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={(e) => setCuentaSelected(e.target.value)}
              value={cuentaSelected}
              label="Seleccione una cuenta"
            >
              <MenuItem value={5}>EFECTIVO EN CAJA</MenuItem>
              <MenuItem value={143}>EFECTIVO EN BANCOS</MenuItem>
            </Select>
          </FormControl>

          <TextField
            style={{ marginTop: 20 }}
            fullWidth
            required
            onChange={(e) => setReferencia(e.target.value.toUpperCase())}
            label={"Concepto de apertura"}
            value={referencia}
          />

          <TextField
            fullWidth
            required
            style={{ marginTop: 20 }}
            onChange={(e) => montoMov(e.target.value)}
            label={
              cuentaSelected === 3
                ? "Monto de Apertura $"
                : "Monto de Apertura C$"
            }
            value={montoApertura}
          />

          <Button
            variant="outlined"
            startIcon={
              <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
            }
            fullWidth
            onClick={() => saveChangesAsync()}
            style={{
              marginTop: 30,
              borderRadius: 20,
              color: "#00a152",
              borderColor: "#00a152",
            }}
            size="large"
          >
            Aperturar
          </Button>
        </Paper>
      </Container>
    </div>
  );
};

export default AperturaContable;
