import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Divider,
} from "@mui/material";
import { isEmpty, isUndefined } from "lodash";

import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";

import { getRol, getToken, getUser } from "../../../api/Account";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import NoData from "../../../components/NoData";
import { getCreditsCancelledAsync } from "../../../api/Report";
import { PrintReport } from "../../../components/PrintReport";
import moment from "moment";
import {
  getGestoresAsync,
  getGestoresBySucursalAccessAsync,
  getSucursalByUserAsync,
} from "../../../api/Users";
import { simpleMessage } from "../../../helpers/Helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx/xlsx.mjs";

const CreditsCancelled = () => {
  const [data, setData] = useState([]);

  const [sucursalName, setSucursalName] = useState("");
  const [sucursalList, setSucursalList] = useState([]);

  const [gestorName, setGestorName] = useState("");
  const [gestorList, setGestorList] = useState([]);

  const compRef = useRef();
  const { selectedSucursal, fechaDesde, fechaHasta, selectedGestor } =
    useParams();

  const { setIsLoading, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const token = getToken();
  const user = getUser();
  const rol = getRol();

  useEffect(() => {
    (async () => {
      let sucursals = await getSucursals();

      if (selectedSucursal === "t") {
        setSucursalName("Todas");
      } else {
        let name = sucursals.filter(
          (item) => item.id.toString() === selectedSucursal
        );
        setSucursalName(name[0].description);
      }

      const data = {
        sucursalId: selectedSucursal === "t" ? 0 : selectedSucursal,
        desde: fechaDesde.toString(),
        hasta: fechaHasta.toString(),
        gestorId: selectedGestor === "t" ? 0 : selectedGestor,
      };

      setIsLoading(true);
      const result = await getCreditsCancelledAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);

      setData(result.data.result);
    })();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(
      selectedSucursal === "t"
        ? result.data.result
        : result.data.result.filter((g) => g.id.toString() === selectedSucursal)
    );

    let gestores = await getGestores(selectedSucursal);

    if (selectedGestor === "t") {
      setGestorName("Todos");
    } else {
      let name = gestores.filter(
        (item) => item.id.toString() === selectedGestor
      );

      setGestorName(name[0].fullName);
    }
    return result.data.result;
  };

  const getGestores = async (sucursal) => {
    setIsLoading(true);
    const result =
      sucursal === "t"
        ? await getGestoresBySucursalAccessAsync(token)
        : await getGestoresAsync(token, sucursal);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    setGestorList(result.data.result);
    return result.data.result;
  };

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 3mm; }
`;

  let totalCobrosConsol = 0;
  let totalCapConsol = 0;
  let totalICConsol = 0;
  let totalCobradoConsol = 0;

  const exportExcel = () => {
    let exp = [];
    data.map((item) => {
      const dataGestor = gestorList.filter(
        (gestor) => gestor.id === item.result.gestor
      );
      let gestor = dataGestor[0];

      let obj = {
        cliente: item.result.fullName,
        telefono: item.result.phone,
        direccion: item.result.address,
        credito: item.result.creditId,
        montoEntregado: item.result.montoEntregado,
        interesCorriente: item.result.interesCorriente,
        periodicidad: item.result.periocidad,
        plazo: item.result.plazo,
        montoRecibido: item.result.montoRecibido,
        fechaEntrega: moment(item.result.fechaEntrega).format("L"),
        fechaVencimiento: moment(item.result.fechaVencimiento).format("L"),
        fechaCancelacion: moment(item.result.fechaCancelacion).format("L"),
        isNominal: item.result.isNominal,
        promedio: item.result.promedio,
        renovo: item.result.isRenovado,
        gestor: isUndefined(gestor) ? item.gestor : gestor.fullName,
        sucursal: item.result.sucursal,
      };

      exp.push(obj);
    });
    var bk = XLSX.utils.book_new(),
      bs = XLSX.utils.json_to_sheet(exp);

    XLSX.utils.book_append_sheet(bk, bs, "creditosCancelados");
    XLSX.writeFile(bk, "CreditosCancelados.xlsx");
  };

  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Stack
            direction={"row"}
            justifyContent="space-between"
            paddingLeft={5}
            paddingRight={5}
            marginTop={1}
            marginBottom={1}
          >
            <IconButton variant="outlined" onClick={() => exportExcel()}>
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                style={{ fontSize: 40, color: "#00a152", width: 40 }}
              />
            </IconButton>

            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {slogan}
              </Typography>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                variant="h6"
                component="div"
              >
                Reporte de Creditos Cancelados
              </Typography>
            </Stack>

            <ReactToPrint
              trigger={() => {
                return (
                  <IconButton variant="outlined">
                    <PrintRoundedIcon
                      style={{ fontSize: 40, color: "#2979ff", width: 40 }}
                    />
                  </IconButton>
                );
              }}
              content={() => compRef.current}
              pageStyle={pageStyle}
            />
          </Stack>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Gestor Seleccionado: ${
                    selectedGestor === "t" ? "Todos" : gestorName
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Desde: ${moment(fechaDesde).format("L")} - Hasta:  ${moment(
                    fechaHasta
                  ).format("L")}`}
                </Typography>
              </Stack>

              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>
        </Stack>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <hr />
          {isEmpty(data) ? (
            <NoData />
          ) : (
            <>
              {sucursalList.map((suc) => {
                const sSucursal = data.filter(
                  (c) => c.result.sucursal === suc.description
                );

                return (
                  <Stack key={suc.description}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        Sucursal:
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        {`${suc.description}`}
                      </Typography>
                    </Stack>
                    {gestorList.map((gestor) => {
                      const cGestor = sSucursal.filter(
                        (c) => c.result.gestor === gestor.id
                      );

                      return isEmpty(cGestor) ? (
                        <></>
                      ) : (
                        <Stack key={gestor.id}>
                          <Stack direction="row" spacing={1}>
                            <Typography
                              sx={{
                                color: "#2196f3",
                                textAlign: "left",
                              }}
                              fontSize={11}
                            >
                              Gestor:
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "left",
                              }}
                              fontSize={11}
                            >
                              {gestor.fullName}
                            </Typography>
                          </Stack>

                          <Divider />

                          <TableContainer>
                            <Table aria-label="simple table" size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="left"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    Cliente
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    Teléfono
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    Dirección
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    Crédito
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    M.Entregado
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    I.C.
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    Perioc.
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    Plazo
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    M.Recibido
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    F.Entrega
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    F.Venc.
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    F.Canc.
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    Nominal
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    Promedio
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-primary fw-bold"
                                    style={{ fontSize: 10 }}
                                  >
                                    Renovo
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {cGestor.map((row) => {
                                  const {
                                    address,
                                    fechaCancelacion,
                                    creditId,
                                    fechaEntrega,
                                    fechaVencimiento,
                                    fullName,
                                    interesCorriente,
                                    isNominal,
                                    isRenovado,
                                    montoEntregado,
                                    montoRecibido,
                                    periocidad,
                                    phone,
                                    plazo,
                                    promedio,
                                  } = row.result;
                                  return (
                                    <TableRow key={row.id}>
                                      <TableCell
                                        align="left"
                                        component="th"
                                        scope="row"
                                        style={{ fontSize: 8 }}
                                      >
                                        {fullName}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 8 }}
                                      >
                                        {phone}
                                      </TableCell>

                                      <TableCell
                                        align="left"
                                        style={{ fontSize: 8 }}
                                      >
                                        {address}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 8 }}
                                      >
                                        {creditId}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 8 }}
                                      >
                                        {new Intl.NumberFormat("es-NI", {
                                          style: "currency",
                                          currency: "NIO",
                                        }).format(montoEntregado)}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 8 }}
                                      >
                                        {`${interesCorriente.toFixed(2)} %`}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 8 }}
                                      >
                                        {periocidad}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 8 }}
                                      >
                                        {plazo}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 8 }}
                                      >
                                        {new Intl.NumberFormat("es-NI", {
                                          style: "currency",
                                          currency: "NIO",
                                        }).format(montoRecibido)}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 8 }}
                                      >
                                        {moment(fechaEntrega).format("L")}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 8 }}
                                      >
                                        {moment(fechaVencimiento).format("L")}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 8 }}
                                      >
                                        {moment(fechaCancelacion).format("L")}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 8 }}
                                      >
                                        {isNominal ? "SI" : "NO"}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 8 }}
                                      >
                                        {promedio.toFixed(2)}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{ fontSize: 8 }}
                                      >
                                        {isRenovado ? "SI" : "NO"}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <hr />
                          <Stack
                            direction="row"
                            flex="row"
                            justifyContent="space-around"
                          >
                            <Stack textAlign="center">
                              <Typography
                                style={{
                                  fontWeight: "bold",
                                  color: "#03a9f4",
                                  fontSize: 11,
                                }}
                              >
                                Total Créditos
                              </Typography>
                              <Typography style={{ fontSize: 11 }}>
                                {cGestor.length}
                              </Typography>
                            </Stack>

                            {/* <Stack textAlign="center">
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#03a9f4",
                                fontSize: 11,
                              }}
                            >
                              Total A Capital
                            </Typography>
                            <Typography style={{ fontSize: 11 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(totalCap)}
                            </Typography>
                          </Stack>

                          <Stack textAlign="center">
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#03a9f4",
                                fontSize: 11,
                              }}
                            >
                              Total A Int. Cnte.
                            </Typography>
                            <Typography style={{ fontSize: 11 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(totalIC)}
                            </Typography>
                          </Stack>

                          <Stack textAlign="center">
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#03a9f4",
                                fontSize: 11,
                              }}
                            >
                              Total Cobrado
                            </Typography>
                            <Typography style={{ fontSize: 11 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(totalCobrado)}
                            </Typography>
                          </Stack> */}
                          </Stack>
                          <hr />
                        </Stack>
                      );
                    })}
                  </Stack>
                );
              })}
            </>
          )}

          {gestorList.length === 1 ? (
            <></>
          ) : (
            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                }}
              >
                Consolidado
              </Typography>

              <hr />

              <Stack direction="row" flex="row" justifyContent="space-around">
                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total de Créditos
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {data.length}
                  </Typography>
                </Stack>
              </Stack>
              <hr />
            </Stack>
          )}
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte de Créditos Cancelados"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Gestor Seleccionado: ${
                    selectedGestor === "t" ? "Todos" : gestorName
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Desde: ${moment(fechaDesde).format("L")} - Hasta:  ${moment(
                    fechaHasta
                  ).format("L")}`}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>

            <>
              <hr />
              {isEmpty(data) ? (
                <NoData />
              ) : (
                <>
                  {sucursalList.map((suc) => {
                    const sSucursal = data.filter(
                      (c) => c.result.sucursal === suc.description
                    );

                    return (
                      <Stack key={suc.description}>
                        <Stack direction="row" spacing={1}>
                          <Typography
                            sx={{
                              color: "#2196f3",
                              textAlign: "left",
                            }}
                            fontSize={11}
                          >
                            Sucursal:
                          </Typography>
                          <Typography
                            sx={{
                              textAlign: "left",
                            }}
                            fontSize={11}
                          >
                            {`${suc.description}`}
                          </Typography>
                        </Stack>

                        {gestorList.map((gestor) => {
                          const cGestor = sSucursal.filter(
                            (c) => c.result.gestor === gestor.id
                          );

                          return isEmpty(cGestor) ? (
                            <></>
                          ) : (
                            <Stack key={gestor.id}>
                              <Stack direction="row" spacing={1}>
                                <Typography
                                  sx={{
                                    color: "#2196f3",
                                    textAlign: "left",
                                  }}
                                  fontSize={11}
                                >
                                  Gestor:
                                </Typography>
                                <Typography
                                  sx={{
                                    textAlign: "left",
                                  }}
                                  fontSize={11}
                                >
                                  {gestor.fullName}
                                </Typography>
                              </Stack>

                              <Divider />

                              <TableContainer>
                                <Table aria-label="simple table" size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        align="left"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        Cliente
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        Teléfono
                                      </TableCell>

                                      <TableCell
                                        align="left"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        Dirección
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        Crédito
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        M.Entregado
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        I.C.
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        Perioc.
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        Plazo
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        M.Recibido
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        F.Entrega
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        F.Venc.
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        F.Canc.
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        Nominal
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        Promedio
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        className="text-primary fw-bold"
                                        style={{ fontSize: 6 }}
                                      >
                                        Renovo
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {cGestor.map((row) => {
                                      const {
                                        address,
                                        fechaCancelacion,
                                        creditId,
                                        fechaEntrega,
                                        fechaVencimiento,
                                        fullName,
                                        interesCorriente,
                                        isNominal,
                                        isRenovado,
                                        montoEntregado,
                                        montoRecibido,
                                        periocidad,
                                        phone,
                                        plazo,
                                        promedio,
                                      } = row.result;
                                      return (
                                        <TableRow key={row.id}>
                                          <TableCell
                                            align="left"
                                            component="th"
                                            scope="row"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {fullName}
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {phone}
                                          </TableCell>

                                          <TableCell
                                            align="left"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {address}
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {creditId}
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {new Intl.NumberFormat("es-NI", {
                                              style: "currency",
                                              currency: "NIO",
                                            }).format(montoEntregado)}
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {`${interesCorriente.toFixed(2)} %`}
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {periocidad}
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {plazo}
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {new Intl.NumberFormat("es-NI", {
                                              style: "currency",
                                              currency: "NIO",
                                            }).format(montoRecibido)}
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {moment(fechaEntrega).format("L")}
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {moment(fechaVencimiento).format(
                                              "L"
                                            )}
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {moment(fechaCancelacion).format(
                                              "L"
                                            )}
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {isNominal ? "SI" : "NO"}
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {promedio.toFixed(2)}
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            style={{ fontSize: 5 }}
                                            className="text-dark"
                                          >
                                            {isRenovado ? "SI" : "NO"}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>

                              <hr />
                              <Stack
                                direction="row"
                                flex="row"
                                justifyContent="space-around"
                              >
                                <Stack textAlign="center">
                                  <Typography
                                    style={{
                                      fontWeight: "bold",
                                      color: "#03a9f4",
                                      fontSize: 11,
                                    }}
                                  >
                                    Total Créditos
                                  </Typography>
                                  <Typography style={{ fontSize: 11 }}>
                                    {cGestor.length}
                                  </Typography>
                                </Stack>

                                {/* <Stack textAlign="center">
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#03a9f4",
                                fontSize: 11,
                              }}
                            >
                              Total A Capital
                            </Typography>
                            <Typography style={{ fontSize: 11 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(totalCap)}
                            </Typography>
                          </Stack>

                          <Stack textAlign="center">
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#03a9f4",
                                fontSize: 11,
                              }}
                            >
                              Total A Int. Cnte.
                            </Typography>
                            <Typography style={{ fontSize: 11 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(totalIC)}
                            </Typography>
                          </Stack>

                          <Stack textAlign="center">
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#03a9f4",
                                fontSize: 11,
                              }}
                            >
                              Total Cobrado
                            </Typography>
                            <Typography style={{ fontSize: 11 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(totalCobrado)}
                            </Typography>
                          </Stack> */}
                              </Stack>
                              <hr />
                            </Stack>
                          );
                        })}
                      </Stack>
                    );
                  })}
                </>
              )}

              {gestorList.length === 1 ? (
                <></>
              ) : (
                <Stack>
                  <Typography
                    sx={{
                      color: "#2196f3",
                      textAlign: "center",
                    }}
                  >
                    Consolidado
                  </Typography>

                  <hr />

                  <Stack
                    direction="row"
                    flex="row"
                    justifyContent="space-around"
                  >
                    <Stack textAlign="center">
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#03a9f4",
                          fontSize: 11,
                        }}
                      >
                        Total de Créditos
                      </Typography>
                      <Typography style={{ fontSize: 11 }}>
                        {data.length}
                      </Typography>
                    </Stack>

                    {/* <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total A Capital
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalCapConsol)}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total A Int. Cnte.
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalICConsol)}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total Cobrado
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalCobradoConsol)}
                  </Typography>
                </Stack> */}
                  </Stack>
                  <hr />
                </Stack>
              )}
            </>
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default CreditsCancelled;
