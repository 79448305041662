import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Button,
  IconButton,
  Container,
  Paper,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCirclePlus,
  faExternalLink,
} from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { DataContext } from "../../../context/DataContext";
import { getRuta, isAccess, simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import NoData from "../../../components/NoData";
import { PaginationComponent } from "../../../components/PaginationComponent";
import { getSucursalByUserAsync } from "../../../api/Users";
import { getArqueosCajaBySucursalAsync } from "../../../api/ArqueoCaja";
import moment from "moment";

const CashRegisterSucursal = () => {
  const [arqueoList, setArquoList] = useState([]);
  const [sucursalList, setSucursalList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [localReload, setLocalReload] = useState(false);

  const {
    setIsLoading,
    setIsUnautorized,
    access,
    selectedSucursal,
    setSelectedSucursal,
  } = useContext(DataContext);

  let ruta = getRuta();
  const token = getToken();
  let navigate = useNavigate();

  const withSearch = arqueoList.filter((val) => {
    if (searchTerm === "") {
      return val;
    } else if (val.gestor.includes(searchTerm)) {
      return val;
    }
  });

  const onChangeSearch = (val) => {
    setCurrentPage(1);
    setSearchTerm(val);
    paginate(1);
  };

  useEffect(() => {
    getSucursals();
  }, [localReload]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsperPage] = useState(10);
  const indexLast = currentPage * itemsperPage;
  const indexFirst = indexLast - itemsperPage;
  const currentItem = arqueoList.slice(indexFirst, indexLast);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(result.data.result);
    setSelectedSucursal(result.data.result[0].id);

    await getArqueoCaja(result.data.result[0].id);
  };

  const getArqueoCaja = async (sucursal) => {
    setIsLoading(true);
    const result = await getArqueosCajaBySucursalAsync(token, sucursal);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);

    setArquoList(result.data.result);
  };

  const onChangeSucursal = (value) => {
    setSelectedSucursal(value);
    getArqueoCaja(value);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Arqueos Sucursal
            </Typography>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <FormControl
                style={{ textAlign: "left", minWidth: 100 }}
                fullWidth
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Sucursal
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  onChange={(e) => onChangeSucursal(e.target.value)}
                  value={selectedSucursal}
                  label="Sucursal"
                >
                  {sucursalList.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {isAccess(access, "CREAR SUCURSAL") ? (
                <Button
                  onClick={() => {
                    navigate(`${ruta}/arqueocajaAdd`);
                  }}
                  startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                  variant="outlined"
                  style={{
                    color: "#00a152",
                    borderColor: "#00a152",
                  }}
                >
                  Agregar Arqueo
                </Button>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>
          <hr />

          <TextField
            style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
            fullWidth
            onChange={(e) => onChangeSearch(e.target.value.toUpperCase())}
            value={searchTerm}
            label={"Buscar Arqueo"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ color: "#1769aa" }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {isEmpty(currentItem) ? (
            <NoData />
          ) : (
            <Stack spacing={2}>
              <TableContainer>
                <Table aria-label="a dense table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        #
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Fecha
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        T.Efectivo
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Recuperacion
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                      >
                        Diferencia
                      </TableCell>
                      <TableCell align="left" className="text-primary fw-bold">
                        Recibido
                      </TableCell>

                      {isAccess(access, "ACAJA VER") ? (
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                        >
                          Ver
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {currentItem.map((row) => {
                      const {
                        diferencia,
                        fechaCreacion,
                        recibidoPor,
                        recuperacion,
                        totalEfectivo,
                        id,
                      } = row;
                      return (
                        <TableRow key={id}>
                          <TableCell align="center" component="th" scope="row">
                            {id}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {moment(fechaCreacion).format("L")}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(totalEfectivo)}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(recuperacion)}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(diferencia)}
                          </TableCell>
                          <TableCell align="left" component="th" scope="row">
                            {recibidoPor}
                          </TableCell>
                          {isAccess(access, "ACAJA VER") ? (
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                            >
                              <IconButton
                                style={{
                                  color: "#ffc400",
                                }}
                                onClick={() => {
                                  navigate(
                                    `${ruta}/arqueosucursal/details/${row.id}`
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faExternalLink} />
                              </IconButton>
                            </TableCell>
                          ) : (
                            <></>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <PaginationComponent
                data={withSearch}
                paginate={paginate}
                itemsperPage={itemsperPage}
              />
            </Stack>
          )}
        </Paper>
      </Container>
    </div>
  );
};

export default CashRegisterSucursal;
