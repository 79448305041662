import React, { useContext, useEffect, useState } from "react";

import { Typography, Stack } from "@mui/material";

import { DataContext } from "../../../../context/DataContext";
import { conv2Letras } from "../../../../helpers/n2Letras";

import { getToken } from "../../../../api/Account";
import { simpleMessage } from "../../../../helpers/Helpers";
import { getClientFullAddressAsync } from "../../../../api/Clients";
import { isEmpty } from "lodash";
import { numeroLetras } from "../../../../helpers/numeroLetras";
import moment from "moment/moment";

export const PrintPagare = React.forwardRef((props, ref) => {
  const [plazoMes, setPlazoMes] = useState(0);
  const [address, setAddress] = useState("");
  const [mes, setMes] = useState("");
  const {
    title,
    slogan,
    icon,
    setIsLoading,
    setIsUnautorized,
    docTitle,
    representanteLegal,
  } = useContext(DataContext);

  const {
    id,
    client,
    montoPagar,
    montoEntregado,
    plazo,
    periocidad,
    cuotaPagar,
    fechaEntrega,
    destino,
    tasaInteresCorriente,
    fechaVencimiento,
    fechaPrimeraCuota,
  } = props.dataCredit;

  const getPlazoMes = () => {
    let plazoDias = periocidad.cuotaDias * plazo.cuotas;
    let plazoMes = plazoDias / periocidad.cantidadDias;

    return plazoMes > 1 ? `${plazoMes} MESES` : `${plazoMes} MES`;
  };

  const getPageMargins = () => {
    return `body {
          color:black;
        }`;
  };

  const token = getToken();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getClientFullAddressAsync(token, client.id);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setAddress(result.data.result);
      setIsLoading(false);
    })();
    setPlazoMes(
      (periocidad.cuotaDias * plazo.cuotas) / periocidad.cantidadDias
    );
    let fecha = new Date(fechaEntrega);

    const monthNameLong = fecha.toLocaleString("es-NI", { month: "long" });
    setMes(monthNameLong);
  }, []);

  return isEmpty(props.dataCredit) ? (
    <></>
  ) : (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <>
        <Stack direction="row" justifyContent="space-between">
          <img src={icon} style={{ width: 70, height: 70 }} />
          <Stack>
            <Typography variant="h5" color="#212121" textAlign={"center"}>
              {title}
            </Typography>
            <Typography color="#212121" textAlign={"center"}>
              {slogan}
            </Typography>
            <Typography variant="h6" color="#212121" textAlign={"center"}>
              Contrato
            </Typography>
          </Stack>
          <img src={icon} style={{ width: 70, height: 70 }} />
        </Stack>

        <Stack direction="row" marginTop={5} marginBottom={5} spacing={1}>
          <Typography color="#212121" fontWeight={"bold"} variant="h5">
            CONTRATO N°:
          </Typography>
          <Typography color="#212121" fontWeight={"bold"} variant="h5">
            {id}
          </Typography>
        </Stack>

        <Typography
          color="#212121"
          variant="body1"
          textAlign="justify"
          fontSize={13}
        >
          <p>
            <strong>
              MUTUO GARANTIZADO CON GARANTIA MOBILIARIA OTORGA A UNA PERSONA.
            </strong>
            <br />
            Nosotros {`${representanteLegal}`}, mayor de edad, Acompañado,
            Licenciado en Contabilidad, de nacionalidad Nicaraguese, con domicilio en esta
            ciudad de Matagalpa, identificado con cédula número cuatro, cuatro,
            uno, guion, uno, nueve, cero, siete, ocho, ocho, guion, cero, cero,
            cero, cuatro, H (441-190788-0004H) a quien en adelante se le llamara
            "El Acreedor" y {`${props.clientName}`} mayor de edad,{" "}
            {`${client.civilStatus}`}, {`${client.actividadEconomica}`}, de este
            domicilio, identificado con cédula de identidad número
            {`(${client.identification})`} a quíen en adelante se le llamara
            "Deudor Mobiliario". El primer compareciente señor{" "}
            {`${representanteLegal}`}, lo hace en nombre y representación de{" "}
            {`${docTitle.toLocaleUpperCase()}`}, persona natural, con domicilio
            y sede central en la ciudad de Matagalpa.-Doy fe de haber tenido
            a la vista en original los atestados que ya relacione, los que
            facultan ampliamente al primer comparecientes suscribir la presente
            escritura sin limitación alguna, el último compareciente lo hacen en
            su propio nombre y representación. Habla el señor(a):{" "}
            {`${props.clientName}`} dice:{" "}
            <strong>CLAUSULA PRIMERA (RELACION DE CRITERIO MUTUO)</strong> Que
            por medio de este Instrumento Público acepta haber recibido del{" "}
            señor: {`${representanteLegal}`}, Representante Legal de{" "}
            {`${docTitle.toLocaleUpperCase()}`}, un Crédito en calidad de Mutuo{" "}
            por la suma de{" "}
            <strong>{`${conv2Letras(
              montoEntregado
            ).toLocaleUpperCase()}`}</strong>{" "}
            (
            {`${new Intl.NumberFormat("es-NI", {
              style: "currency",
              currency: "NIO",
            }).format(montoEntregado)}`}
            ), los cuales confiesa haber tener recibos a su entera satisfacción
            en este acto. El Deudor mobiliario hará uso del crédito recibido en
            calidad de mutuo para invertirlos en {`${destino}`}-{" "}
            <strong>CLAUSULA SEGUNDA (INTERES CORRIENTE Y MORATORIO): </strong>{" "}
            Expresan conuntamente al acreedor y el deudor: Que en el presente
            crédito tendrá una carga financiera del{" "}
            {`${numeroLetras(
              tasaInteresCorriente.intCorriente
            ).toLocaleUpperCase()}`}{" "}
            por ciento mensual (-{`${tasaInteresCorriente.intCorriente}%`})
            sobre saldo, porcentaje que incluye interes corriente mas{" "}
            {`${numeroLetras(
              tasaInteresCorriente.intCorriente
            ).toLocaleUpperCase()}`}{" "}
            por ciento mensual (-{`${tasaInteresCorriente.intCorriente}%`}) de
            comision por seguimiento de crédito. En caso de mora el presente
            crédito generará una carga adicional, de interes moratorio de 00/100
            por ciento (0.00%) de la tasa de interes corriente sobre el monto en
            moray días atrasados. -
            <strong>CLAUSULA TERCERO (PLAZO Y FORMA DE PAGO): </strong> El
            crédito antes referido será pagado en un plazo de{" "}
            {`${getPlazoMes()}`} siendo su fecha de vencimiento el{" "}
            {`${moment(fechaVencimiento).format("dddd,")} ${moment(
              fechaVencimiento
            ).format("D")} de ${moment(fechaVencimiento).format(
              "MMMM"
            )} de ${moment(fechaVencimiento).format("YYYY")}`}{" "}
            y su forma de pago sera en cuotas {`${periocidad.description}`}{" "}
            iniciando el pago de la primera cuota el{" "}
            {`${moment(fechaPrimeraCuota).format("dddd,")} ${moment(
              fechaPrimeraCuota
            ).format("D")} de ${moment(fechaPrimeraCuota).format(
              "MMMM"
            )} de ${moment(fechaPrimeraCuota).format("YYYY")}`}{" "}
            hasta por la suma de{" "}
            <strong>{`${conv2Letras(cuotaPagar).toLocaleUpperCase()}`}</strong>{" "}
            (
            {`${new Intl.NumberFormat("es-NI", {
              style: "currency",
              currency: "NIO",
            }).format(cuotaPagar)}`}
            ) CADA CUOTA, que incluyen el interes pactado, las que deberian ser
            depositadas por el deudor en las oficinas de{" "}
            {`${docTitle.toLocaleUpperCase()}`}, o en manos del gestor de cobro
            de la institución debidamente identificado.{" "}
            {`${docTitle.toLocaleUpperCase()}`} en este acto entrega Plan de
            Pago al deudor mobiliario, el que refleja el monto de cada cuota y
            la fecha de pago. La falta de pago del deudor de sus obligaciones en
            las fechas convenidas en la presente escritura, lo hara incurrir en
            MORA sin necesidad de requerimiento o cobro alguno judicial o
            extrajudicial, debiendo reconocer los intereses penales o moratorios
            convenidos, pudiendo el acreedor dar por vencido el total de lo
            adeudado y proceder a su inmediato cobro con sólo faltar al pago de
            una de las cuotas convenidas. -Habla el deudor señor(a):{" "}
            {`${props.clientName}`} y dice:{" "}
            <strong>
              CLAUSULA CUATRO (CONSTITUCION DE GARANTIA MOBILIARIA):{" "}
            </strong>{" "}
            que para garantizar la obligacion del mutuo adquirida con{" "}
            {`${docTitle.toLocaleUpperCase()}`}, en este acto contituye Garantia
            Mobiliaria Civil sobre los siguientes bienes muebles de su
            propiedad:. -Sigue diciendo el compareciente que: a) Constituye
            deposito convencional sobre la garantia mobiliaria ampliamente
            descrita y especificada en esta cláusula, quedando la misma en poder
            de su dueño como depositario con obligacion de custodiarla, cuidarla
            y entregarlaal acreedor cuando fuere requerido para tal fin,
            pudiendo hacer cesar el presente deposito convencional una vez que
            cancele el crédito, todo conforme lo establecido en el artículo 3449
            y siguientes del Código Civil Vigente de Nicaragua.- b) El deudor
            conservará el nombre del acreedor la posesion de los objetos
            pignorados, pudiendo usar de ellos sin menoscabo de su valor,
            estando obligado a realizar por su cuenta los trabajos de
            mantenimiento, conservacion y reparacion. c) El deudor tendrá
            respecto de los bienes pignorados los deberes y responsabilidadesde
            los depositarios. d) El deudor se obliga a informar por escrito al
            acreedor cualquier desmérito, desmejoras, perjuicios o daños que
            pudieren ocurrirle a los bienes pignorados, debiendo hacerlo dentro
            de un plazo no mayor a cuarenta y ocho horas de ocurrido. d) Los
            bienes pignorados no podranser sacados del domicilio del deudor, que
            es donde se encuentran, con permiso escrito del acreedor. e) Queda
            prohibido al deudor celebrar otro contrato de garantia mobiliaria
            sobre bienes pignorados. f) El deudor asume lo relativo y legalmente
            establecido en cuanto al Apremio Corporal en materia civil, artos y
            siguientes,por lo que hace la presentación de las garantias
            mobiliarias.-{" "}
            <strong>
              CLAUSULA QUINTA (RECARGOS POR COBRANZA ADMINISTRATIVA Y COBRANZA
              EXTRAJUDICIAL):
            </strong>{" "}
            El deudor en caso de incurrir en mora, despues del primer dia de
            atraso reconocerá y pagaráal deudor en concepto de cobranza
            administrativa el diez por ciento (10%) calculado sobre la cuota de
            amortización en mora y los días atrasados. Habla "El deudor
            mobiliaria" y dice{" "}
            <strong>CLAUSULA SEXTA (VENCIMIENTO ANTICIPADO):</strong> Que el
            plazo prefijado para el Préstamo, podra ser exigible de inmediato
            por el acreedor en los siguientes casos: a) Si faltara al pago de
            una sola de las cuotas convenidas, en la fecha fijada. b) Si el
            deudor diere a los fondos un uso distinto al convenido en el
            presente contrato. c) Si el deudor fuere ejecutado judicialmente por
            cualquier persona natural o juridica durante la vigencia del
            crédito. d) Si sobre el bien hipotecario o garantia mobiliaria
            cayere embargo o ejecución. e) Si el deudor gravere nuevamente el
            bien dado en hipoteca o garantia mobiliaria sin autorización del
            acreedor. f) Si se proporcionare datos o informaciones falsas a{" "}
            {`${docTitle.toLocaleUpperCase()}`}, el fiador o los bienes
            mobiliarios.- <strong>CLAUSULA SEPTIMA (RENUNCIAS):</strong> El
            deudor mobiliario y los fiadores solidarios dicen RENUNCIAN: a) A
            las excepciones provenientes del caso fortuito y fuerza mayor, cuyos
            riesgos asumen en su totalidad. b) a los trámites del juicio de
            Ejecución Forzosa y se someten al que su Acreedor elija y a su vez
            autoriza al acreedor a subastar o rematar los bienes mobiliario o
            hipotecados, por el valor a que estubiere reducida la deudaa dicha
            fecha. c) Al derecho de ser intimado de pagopara los efectos de
            mora; d) A gozar del plazo del préstamo por falta de pago del
            interés y cuotas de amortizacion de lo prestado, en tal caso el
            acreedor podra dar por vencido el plazo de la obligación y exigir
            judicialmente el saldo de la misma existiera, con al falta de pago
            de una sola cuota a las que se ha obligado. e) Al derecho de ser
            depositario de los bienes que se le enbarguen. f) Al derecho de
            perseguir primero la garantia constituida, pues el acreedor podra
            perseguir antes, despues o simultaneamente con el bien mobiliario,
            otros bienes del deudor sin perder su derecho preferente. g) Al
            derecho de ser notificado de cualquier cesión y/o subrogación que de
            este crédito haga el acreedor al cualquier persona natural o
            juridica. h) Al derecho de nombrar perito valuador de bienes
            inmuebles o grabados. i) A que se valore en bien dado en hipoteca o
            garantías mobiliarias. Hablan en forma conjunta todos los
            comparecientes y dicen:{" "}
            <strong>CLAUSULA OCTAVA (ACEPTACION):</strong> Que en los téminos y
            condiciones relacionados en este instrumento público, ACEPTAN, el
            contrato de MUTUO GARANTIZADO CON GARANTIAS BOBILIARIA, OTORGADO A
            UNA PERSONA.- Así se expresaron los comparecientes bien
            instruidospor mi la Notaria, acerca del objeto, valor y
            trascendencialegal de este acto, de las cláusulas generales que
            aseguran su validez, de las especiales que contiene, de las
            estipulacionesy renuncias implícitas y explícitas. Y leida que fue a
            los comparecientes, la encuentran conforme, aprueban, ratifican y
            firman junto conmigola Notario que doy fe de todo lo aquí
            relacionado. - <br />
            <br />
            <br />
            <br />
            Dado en CHINANDEGA el{" "}
            {`${moment(fechaVencimiento).format("dddd,")} ${moment(
              fechaVencimiento
            ).format("D")} de ${moment(fechaVencimiento).format(
              "MMMM"
            )} de ${moment(fechaVencimiento).format("YYYY")}`}
          </p>
        </Typography>

        <Stack
          direction={"row"}
          spacing={2}
          marginTop={15}
          justifyContent="space-around"
          alignItems="flex-start"
        >
          <Stack justifyContent="center">
            <span>----------------------------------------------------</span>
            <Typography textAlign={"center"} color="#212121" fontSize={13}>
              {`${representanteLegal}`}
            </Typography>
          </Stack>

          <Stack justifyContent="center">
            <span>----------------------------------------------------</span>

            <Typography textAlign={"center"} color="#212121" fontSize={13}>
              {`${props.clientName}`}
            </Typography>
            <Typography textAlign={"center"} color="#212121" fontSize={13}>
              {`${client.identification}`}
            </Typography>
          </Stack>
        </Stack>
      </>
    </div>
  );
});

export default PrintPagare;
