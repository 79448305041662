import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Paper,
} from "@mui/material";
import { isEmpty } from "lodash";

import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";

import { getRol, getToken, getUser } from "../../../api/Account";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import NoData from "../../../components/NoData";
import { PrintReport } from "../../../components/PrintReport";
import moment from "moment";
import { getSucursalByUserAsync } from "../../../api/Users";
import { simpleMessage } from "../../../helpers/Helpers";
import { getBalanzaCompAsync } from "../../../api/AdmonReport";

const ReporteBalanzaComprobacion = () => {
  const [data, setData] = useState([]);

  const [sucursalName, setSucursalName] = useState("");

  const compRef = useRef();
  const { selectedSucursal, fechaDesde, fechaHasta, selectedGestor } =
    useParams();

  const { setIsLoading, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const token = getToken();
  const user = getUser();
  const rol = getRol();

  useEffect(() => {
    (async () => {
      let sucursals = await getSucursals();

      if (selectedSucursal === "t") {
        setSucursalName("Todas");
      } else {
        let name = sucursals.filter(
          (item) => item.id.toString() === selectedSucursal
        );
        setSucursalName(name[0].description);
      }

      const data = {
        desde: fechaDesde.toString(),
        hasta: fechaHasta.toString(),
        gestorId: selectedGestor === "t" ? 0 : selectedGestor,
        sucursalId: selectedSucursal === "t" ? 0 : selectedSucursal,
      };

      setIsLoading(true);
      const result = await getBalanzaCompAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setData(result.data.result);
    })();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    return result.data.result;
  };

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 8mm; }
`;

  let sumCargo = 0;
  let sumAbono = 0;
  let sumDebe = 0;
  let sumHaber = 0;

  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Typography
            sx={{
              color: "#2196f3",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {slogan}
          </Typography>

          <Typography
            sx={{
              color: "#2196f3",
              textAlign: "center",
              fontWeight: "bold",
            }}
            variant="h6"
            component="div"
          >
            Balanza de Comprobación
          </Typography>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>

                <Stack direction={"row"} spacing={1}>
                  <Typography fontSize={11} textAlign="left">
                    Desde:
                  </Typography>

                  <Typography fontSize={11} textAlign="left">
                    {moment(fechaDesde).format("L")}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography fontSize={11} textAlign="left">
                    Hasta:
                  </Typography>

                  <Typography fontSize={11} textAlign="left">
                    {moment(fechaHasta).format("L")}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          <ReactToPrint
            trigger={() => {
              return (
                <IconButton
                  variant="outlined"
                  style={{ position: "fixed", right: 20, top: 75 }}
                >
                  <PrintRoundedIcon
                    style={{ fontSize: 35, color: "#2979ff", width: 35 }}
                  />
                </IconButton>
              );
            }}
            content={() => compRef.current}
            pageStyle={pageStyle}
          />
        </Stack>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <hr />

          {isEmpty(data) ? (
            <NoData />
          ) : (
            <>
              <Paper
                style={{
                  padding: 20,
                }}
              >
                <TableContainer>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                          rowSpan={2}
                        >
                          N°.
                        </TableCell>

                        <TableCell
                          align="left"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                          rowSpan={2}
                        >
                          Cuenta
                        </TableCell>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                          colSpan={2}
                        >
                          Movimientos
                        </TableCell>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                          colSpan={2}
                        >
                          Saldos
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                        >
                          Cargo
                        </TableCell>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                        >
                          Abono
                        </TableCell>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                        >
                          Debe
                        </TableCell>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                        >
                          Haber
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {data.map((row) => {
                        sumCargo += row.cargo;
                        sumAbono += row.abono;
                        sumDebe += row.debe;
                        sumHaber += row.haber;
                        return (
                          <TableRow key={data.indexOf(row) + 1}>
                            <TableCell
                              align="left"
                              component="th"
                              scope="row"
                              style={{ fontSize: 11 }}
                            >
                              {data.indexOf(row) + 1}
                            </TableCell>
                            <TableCell align="left" style={{ fontSize: 11 }}>
                              {row.cuentaContable.descripcion}
                            </TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            >
                              {row.cargo === 0
                                ? ""
                                : new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.cargo)}
                            </TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            >
                              {row.abono === 0
                                ? ""
                                : new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.abono)}
                            </TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            >
                              {row.debe === 0
                                ? ""
                                : new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.debe)}
                            </TableCell>
                            <TableCell
                              align="center"
                              scope="row"
                              style={{ fontSize: 12 }}
                            >
                              {row.haber === 0
                                ? ""
                                : new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.haber)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow key={0}>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                          colSpan={2}
                        >
                          Totales
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumCargo)}
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumAbono)}
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumDebe)}
                        </TableCell>
                        <TableCell
                          align="center"
                          scope="row"
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumHaber)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
          )}
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte Estado de Resultado"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <hr />

            {isEmpty(data) ? (
              <NoData />
            ) : (
              <TableContainer>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                        style={{ fontSize: 12 }}
                        rowSpan={2}
                      >
                        N°.
                      </TableCell>

                      <TableCell
                        align="left"
                        className="text-primary fw-bold"
                        style={{ fontSize: 12 }}
                        rowSpan={2}
                      >
                        Cuenta
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                        style={{ fontSize: 12 }}
                        colSpan={2}
                      >
                        Movimientos
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                        style={{ fontSize: 12 }}
                        colSpan={2}
                      >
                        Saldos
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                        style={{ fontSize: 12 }}
                      >
                        Cargo
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                        style={{ fontSize: 12 }}
                      >
                        Abono
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                        style={{ fontSize: 12 }}
                      >
                        Debe
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-primary fw-bold"
                        style={{ fontSize: 12 }}
                      >
                        Haber
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data.map((row) => {
                      sumCargo += row.cargo;
                      sumAbono += row.abono;
                      sumDebe += row.debe;
                      sumHaber += row.haber;
                      return (
                        <TableRow key={data.indexOf(row) + 1}>
                          <TableCell
                            align="left"
                            component="th"
                            scope="row"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {data.indexOf(row) + 1}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {row.cuentaContable.descripcion}
                          </TableCell>

                          <TableCell
                            align="center"
                            scope="row"
                            style={{ fontSize: 12 }}
                            className="text-dark"
                          >
                            {row.cargo === 0
                              ? ""
                              : new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(row.cargo)}
                          </TableCell>

                          <TableCell
                            align="center"
                            scope="row"
                            style={{ fontSize: 12 }}
                            className="text-dark"
                          >
                            {row.abono === 0
                              ? ""
                              : new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(row.abono)}
                          </TableCell>

                          <TableCell
                            align="center"
                            scope="row"
                            style={{ fontSize: 12 }}
                            className="text-dark"
                          >
                            {row.debe === 0
                              ? ""
                              : new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(row.debe)}
                          </TableCell>

                          <TableCell
                            align="center"
                            scope="row"
                            style={{ fontSize: 12 }}
                            className="text-dark"
                          >
                            {row.haber === 0
                              ? ""
                              : new Intl.NumberFormat("es-NI", {
                                  style: "currency",
                                  currency: "NIO",
                                }).format(row.haber)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow key={0}>
                      <TableCell
                        align="center"
                        className="text-dark"
                        style={{
                          fontSize: 15,
                          fontWeight: "bold",
                        }}
                        colSpan={2}
                      >
                        Totales
                      </TableCell>
                      <TableCell
                        align="center"
                        scope="row"
                        className="text-dark"
                        style={{ fontSize: 15, fontWeight: "bold" }}
                      >
                        {new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(sumCargo)}
                      </TableCell>
                      <TableCell
                        align="center"
                        scope="row"
                        className="text-dark"
                        style={{ fontSize: 15, fontWeight: "bold" }}
                      >
                        {new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(sumAbono)}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="text-dark"
                        scope="row"
                        style={{ fontSize: 15, fontWeight: "bold" }}
                      >
                        {new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(sumDebe)}
                      </TableCell>
                      <TableCell
                        align="center"
                        scope="row"
                        className="text-dark"
                        style={{ fontSize: 15, fontWeight: "bold" }}
                      >
                        {new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(sumHaber)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default ReporteBalanzaComprobacion;
