import React, { useState, useContext, useEffect } from 'react';
import {
	TextField,
	InputAdornment,
	Container,
	Button,
	Stack,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { getToken } from '../../../api/Account';
import { DataContext } from '../../../context/DataContext';
import { simpleMessage } from '../../../helpers/Helpers';
import { inyectarCapitalAsync } from '../../../api/Cash';
import { getSucursalByUserAsync } from '../../../api/Users';

const InyectarCapital = ({
	setShowModal,
	localReload,
	setLocalReload,
	selectedSucursal,
	selectedTipoFlujo
}) => {
	const [sucursalList, setSucursalList] = useState([]);
	const [sucursal, setSucursal] = useState(selectedSucursal);
	const [tf, setTF] = useState(selectedTipoFlujo);
	const [monto, setMonto] = useState('');
	const [description, setDescription] = useState('');

	const { setIsLoading, setIsUnautorized } = useContext(DataContext);

	const token = getToken();

	const getSucursals = async () => {
		setIsLoading(true);
		const result = await getSucursalByUserAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setSucursalList(result.data.result);
	};

	useEffect(() => {
		(async () => {
			await getSucursals();
		})();
	}, [localReload]);

	const montoMov = (value) => {
		if (/^\d*\.?\d*$/.test(value.toString()) || value === '') {
			setMonto(value);
			return;
		}
	};

	const saveChangesAsync = async () => {
		if (monto.length === 0) {
			simpleMessage('Ingrese un monto válido...', 'error');
			return;
		}

		if (description.length === 0) {
			simpleMessage('Ingrese una descripcion válida...', 'error');
			return;
		}

		const data = {
			sucursalId: sucursal,
			idCuenta: tf,
			monto,
			description
		};

		setIsLoading(true);
		const result = await inyectarCapitalAsync(token, data);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setIsLoading(false);
		simpleMessage('Inyeccion de capital realizada...!', 'success');
		setLocalReload(!localReload);
		setShowModal(false);
	};

	return (
		<div>
			<Container style={{ width: 500 }}>
				<hr />

				<Stack spacing={2}>
					<FormControl style={{ textAlign: 'left' }} fullWidth>
						<InputLabel id="demo-simple-select-standard-label">Sucursal</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							onChange={(e) => setSucursal(e.target.value)}
							value={sucursal}
							label="Sucursal"
						>
							{sucursalList.map((item) => {
								return (
									<MenuItem key={item.id} value={item.id}>
										{item.description}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>

					<FormControl style={{ textAlign: 'left', minWidth: 100 }} fullWidth>
						<InputLabel id="demo-simple-select-standard-label">Cuenta</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							onChange={(e) => setTF(e.target.value)}
							value={tf}
							label="Cuenta"
						>
							<MenuItem value={5}>EFECTIVO EN CAJA 1</MenuItem>
							<MenuItem value={145}>EFECTIVO EN CAJA 2</MenuItem>
							<MenuItem value={146}>EFECTIVO EN CAJA CHICA</MenuItem>
							<MenuItem value={143}>EFECTIVO EN BANCOS</MenuItem>
						</Select>
					</FormControl>

					<TextField
						fullWidth
						required
						onChange={(e) => montoMov(e.target.value)}
						label={'Monto a ingresar'}
						value={monto}
						InputProps={{
							startAdornment: <InputAdornment position="start">C$</InputAdornment>
						}}
					/>

					<TextField
						fullWidth
						required
						onChange={(e) => setDescription(e.target.value.toUpperCase())}
						label={'Descripcion del movimiento'}
						value={description}
					/>

					<Button
						fullWidth
						variant="outlined"
						style={{
							color: '#00a152',
							borderColor: '#00a152'
						}}
						startIcon={<FontAwesomeIcon icon={faSave} />}
						onClick={() => saveChangesAsync()}
					>
						Inyectar Capital
					</Button>
				</Stack>
			</Container>
		</div>
	);
};

export default InyectarCapital;
