import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Divider,
} from "@mui/material";
import { isEmpty } from "lodash";

import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";

import { getRol, getToken, getUser } from "../../../api/Account";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import NoData from "../../../components/NoData";

import { PrintReport } from "../../../components/PrintReport";
import moment from "moment";
import { getSucursalByUserAsync } from "../../../api/Users";
import { simpleMessage } from "../../../helpers/Helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx/xlsx.mjs";
import { getCashFlowsHistoryAsync } from "../../../api/AdmonReport";

const ReporteCashFlowHistory = () => {
  const [data, setData] = useState([]);

  const [sucursalName, setSucursalName] = useState("");
  const [sucursalList, setSucursalList] = useState([]);
  const compRef = useRef();
  const { selectedSucursal, fechaDesde, fechaHasta, selectedGestor } =
    useParams();

  const { setIsLoading, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const token = getToken();
  const user = getUser();
  const rol = getRol();

  useEffect(() => {
    (async () => {
      let sucursals = await getSucursals();

      if (selectedSucursal === "t") {
        setSucursalName("Todas");
      } else {
        let name = sucursals.filter(
          (item) => item.id.toString() === selectedSucursal
        );
        setSucursalName(name[0].description);
      }

      const data = {
        sucursalId: selectedSucursal === "t" ? 0 : selectedSucursal,
        desde: fechaDesde.toString(),
        hasta: fechaHasta.toString(),
        gestorId: selectedGestor === "t" ? 0 : selectedGestor,
      };

      setIsLoading(true);
      const result = await getCashFlowsHistoryAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);

      setData(result.data.result);
    })();
  }, []);

  const getSucursals = async () => {
    setIsLoading(true);
    const result = await getSucursalByUserAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setIsLoading(false);
    setSucursalList(
      selectedSucursal === "t"
        ? result.data.result
        : result.data.result.filter((g) => g.id.toString() === selectedSucursal)
    );

    return result.data.result;
  };

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page {  size: landscape; margin: 3mm; }
`;

  const exportExcel = () => {
    let exp = [];
    data.map((item) => {
      let obj = {
        id: item.id,
        fecha: item.fecha,
        description: item.description,
        tipoFlujo: item.tipoFlujo,
        entradas: item.entradas,
        salidas: item.salidas,
        saldo: item.saldo,
        payWith: item.payWith,
        realizadoPor: item.realizadoPor,
        sucursal: item.sucursal.description,
      };

      exp.push(obj);
    });
    var bk = XLSX.utils.book_new(),
      bs = XLSX.utils.json_to_sheet(exp);

    XLSX.utils.book_append_sheet(bk, bs, "historialGastos");
    XLSX.writeFile(bk, "HistorialGastos.xlsx");
  };

  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Stack
            direction={"row"}
            justifyContent="space-between"
            paddingLeft={5}
            paddingRight={5}
            marginTop={1}
            marginBottom={1}
          >
            <IconButton variant="outlined" onClick={() => exportExcel()}>
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                style={{ fontSize: 40, color: "#00a152", width: 40 }}
              />
            </IconButton>

            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {slogan}
              </Typography>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                variant="h6"
                component="div"
              >
                Reporte Movimientos de Efectivo
              </Typography>
            </Stack>

            <ReactToPrint
              trigger={() => {
                return (
                  <IconButton variant="outlined">
                    <PrintRoundedIcon
                      style={{ fontSize: 40, color: "#2979ff", width: 40 }}
                    />
                  </IconButton>
                );
              }}
              content={() => compRef.current}
              pageStyle={pageStyle}
            />
          </Stack>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Desde: ${moment(fechaDesde).format("L")}`}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Hasta:  ${moment(fechaHasta).format("L")}`}
                </Typography>
              </Stack>

              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>
        </Stack>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <hr />

          {isEmpty(data) ? (
            <NoData />
          ) : (
            <>
              {sucursalList.map((suc) => {
                const sSucursal = data.filter(
                  (c) => c.sucursal.description === suc.description
                );

                return isEmpty(sSucursal) ? (
                  <></>
                ) : (
                  <Stack key={suc.description}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        Sucursal:
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        {`${suc.description}`}
                      </Typography>
                    </Stack>

                    <Divider />

                    <TableContainer style={{ marginBottom: 30 }}>
                      <Table aria-label="simple table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 13 }}
                            >
                              #
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 13 }}
                            >
                              Fecha
                            </TableCell>
                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 13 }}
                            >
                              Descripcion
                            </TableCell>

                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 13 }}
                            >
                              Fondos
                            </TableCell>

                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 13 }}
                            >
                              Entrada
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 13 }}
                            >
                              Salida
                            </TableCell>
                            <TableCell
                              align="center"
                              className="text-primary fw-bold"
                              style={{ fontSize: 13 }}
                            >
                              Saldo
                            </TableCell>

                            <TableCell
                              align="left"
                              className="text-primary fw-bold"
                              style={{ fontSize: 13 }}
                            >
                              Realizado por
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sSucursal.map((row) => {
                            return (
                              <TableRow key={row.id}>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.id}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {moment(row.fecha).format("L")}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.description}
                                </TableCell>

                                <TableCell
                                  align="left"
                                  style={{ fontSize: 11 }}
                                >
                                  {row.tipoFlujo}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.entradas)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.salidas)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: 11 }}
                                >
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(row.saldo)}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ fontSize: 11 }}
                                >
                                  {`${row.realizadoPor.split(" ")[0]}  ${
                                    row.realizadoPor.split(" ")[1]
                                  }`}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Stack>
                );
              })}
            </>
          )}
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte de Movimientos de Efectivo"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Desde: ${moment(fechaDesde).format("L")}`}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Hasta:  ${moment(fechaHasta).format("L")}`}
                </Typography>
              </Stack>

              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <hr />

            {isEmpty(data) ? (
              <NoData />
            ) : (
              <>
                {sucursalList.map((suc) => {
                  const sSucursal = data.filter(
                    (c) => c.sucursal.description === suc.description
                  );

                  return isEmpty(sSucursal) ? (
                    <></>
                  ) : (
                    <Stack key={suc.description}>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          sx={{
                            color: "#2196f3",
                            textAlign: "left",
                          }}
                          fontSize={11}
                        >
                          Sucursal:
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "left",
                          }}
                          fontSize={11}
                        >
                          {`${suc.description}`}
                        </Typography>
                      </Stack>

                      <Divider />

                      <TableContainer style={{ marginBottom: 30 }}>
                        <Table aria-label="simple table" size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 13 }}
                              >
                                #
                              </TableCell>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 13 }}
                              >
                                Fecha
                              </TableCell>
                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 13 }}
                              >
                                Descripcion
                              </TableCell>

                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 13 }}
                              >
                                Fondos
                              </TableCell>

                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 13 }}
                              >
                                Entrada
                              </TableCell>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 13 }}
                              >
                                Salida
                              </TableCell>
                              <TableCell
                                align="center"
                                className="text-primary fw-bold"
                                style={{ fontSize: 13 }}
                              >
                                Saldo
                              </TableCell>

                              <TableCell
                                align="left"
                                className="text-primary fw-bold"
                                style={{ fontSize: 13 }}
                              >
                                Realizado por
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sSucursal.map((row) => {
                              return (
                                <TableRow key={row.id}>
                                  <TableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                    className="text-dark"
                                    style={{ fontSize: 11 }}
                                  >
                                    {row.id}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-dark"
                                    style={{ fontSize: 11 }}
                                  >
                                    {moment(row.fecha).format("L")}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className="text-dark"
                                    style={{ fontSize: 11 }}
                                  >
                                    {row.description}
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    className="text-dark"
                                    style={{ fontSize: 11 }}
                                  >
                                    {row.tipoFlujo}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-dark"
                                    style={{ fontSize: 11 }}
                                  >
                                    {new Intl.NumberFormat("es-NI", {
                                      style: "currency",
                                      currency: "NIO",
                                    }).format(row.entradas)}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className="text-dark"
                                    style={{ fontSize: 11 }}
                                  >
                                    {new Intl.NumberFormat("es-NI", {
                                      style: "currency",
                                      currency: "NIO",
                                    }).format(row.salidas)}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ fontSize: 11 }}
                                    className="text-dark"
                                  >
                                    {new Intl.NumberFormat("es-NI", {
                                      style: "currency",
                                      currency: "NIO",
                                    }).format(row.saldo)}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className="text-dark"
                                    style={{ fontSize: 11 }}
                                  >
                                    {`${row.realizadoPor.split(" ")[0]}  ${
                                      row.realizadoPor.split(" ")[1]
                                    }`}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  );
                })}
              </>
            )}
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default ReporteCashFlowHistory;
