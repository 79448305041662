import React, { useState, useContext, useEffect } from 'react';
import {
	Button,
	Paper,
	Typography,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Table,
	IconButton
} from '@mui/material';
import { isEmpty } from 'lodash';
import { Stack } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCirclePlus,
	faCircleCheck,
	faCircleXmark,
	faExternalLink
} from '@fortawesome/free-solid-svg-icons';
import { getToken } from '../../../api/Account';
import { DataContext } from '../../../context/DataContext';

import { getRuta, isAccess, simpleMessage } from '../../../helpers/Helpers';

import NoData from '../../../components/NoData';
import { PaginationComponent } from '../../../components/PaginationComponent';
import { useNavigate } from 'react-router-dom';

import { getCreditsByClientAsync } from '../../../api/Clients';
import moment from 'moment/moment';

const ClientCredits = ({ id }) => {
	const token = getToken();
	let ruta = getRuta();
	let navigate = useNavigate();
	const [creditList, setCreditList] = useState([]);

	const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);

	const [searchTerm, setSearchTerm] = useState('');

	const withSearch = creditList.filter((val) => {
		if (searchTerm === '') {
			return val;
		} else if (val.id.toString().includes(searchTerm)) {
			return val;
		}
	});

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const result = await getCreditsByClientAsync(token, id);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);
			setCreditList(result.data.result);
		})();
	}, []);

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsperPage] = useState(10);
	const indexLast = currentPage * itemsperPage;
	const indexFirst = indexLast - itemsperPage;
	const currentItem = withSearch.slice(indexFirst, indexLast);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div>
			<Paper
				style={{
					padding: 20
				}}
			>
				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					alignItems="center"
					justifyContent={'space-between'}
				>
					<Typography color={'#00a152'} variant="h6" fontWeight={'bold'}>
						Creditos del Cliente
					</Typography>

					{isAccess(access, 'CREDITS CREATE') ? (
						<Button
							onClick={() => {
								navigate(`${ruta}/credit/add/${id}`);
							}}
							style={{
								color: '#00a152',
								borderColor: '#00a152'
							}}
							variant="outlined"
						>
							<FontAwesomeIcon
								style={{ marginRight: 10, fontSize: 20 }}
								icon={faCirclePlus}
							/>
							Agregar
						</Button>
					) : (
						<></>
					)}
				</Stack>

				<hr />

				{isEmpty(currentItem) ? (
					<NoData />
				) : (
					<Stack spacing={2}>
						<TableContainer>
							<Table aria-label="simple table" size="small">
								<TableHead>
									<TableRow>
										<TableCell align="center" className="text-primary fw-bold">
											#
										</TableCell>
										<TableCell align="center" className="text-primary fw-bold">
											Monto Entregado
										</TableCell>
										<TableCell align="center" className="text-primary fw-bold">
											Cuota Principal
										</TableCell>
										<TableCell align="center" className="text-primary fw-bold">
											Fecha Vencimiento
										</TableCell>
										<TableCell align="center" className="text-primary fw-bold">
											Estadistica
										</TableCell>
										<TableCell align="center" className="text-primary fw-bold">
											Cancelado
										</TableCell>
										{isAccess(access, 'CREDITS VER') ? (
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Ir
											</TableCell>
										) : (
											<></>
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{currentItem.map((row) => {
										return (
											<TableRow key={row.id}>
												<TableCell
													align="center"
													component="th"
													scope="row"
												>
													{row.id}
												</TableCell>
												<TableCell
													align="center"
													component="th"
													scope="row"
												>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(row.montoEntregado)}
												</TableCell>
												<TableCell
													align="center"
													component="th"
													scope="row"
												>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(row.cuotaPagar)}
												</TableCell>
												<TableCell
													align="center"
													component="th"
													scope="row"
												>
													{moment(row.fechaVencimiento).format('L')}
												</TableCell>
												<TableCell
													align="center"
													component="th"
													scope="row"
												>
													{row.promedio.toFixed(2)}
												</TableCell>
												<TableCell
													align="center"
													component="th"
													scope="row"
												>
													{row.isCanceled}
													<FontAwesomeIcon
														icon={
															row.isCanceled
																? faCircleCheck
																: faCircleXmark
														}
														color={
															row.isCanceled ? '#00a152' : '#f50057'
														}
														fontSize={20}
													/>
												</TableCell>
												<TableCell
													align="center"
													component="th"
													scope="row"
												>
													<IconButton
														onClick={() => {
															navigate(`${ruta}/credit/${row.id}`);
														}}
													>
														<FontAwesomeIcon
															icon={faExternalLink}
															color={'#2979ff'}
															fontSize={20}
														/>
													</IconButton>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>

						<PaginationComponent
							data={withSearch}
							paginate={paginate}
							itemsperPage={itemsperPage}
						/>
					</Stack>
				)}
			</Paper>
		</div>
	);
};

export default ClientCredits;
