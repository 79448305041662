import axios from "axios";
import { errorResponse } from "../helpers/Helpers";
const { REACT_APP_PRODURL, REACT_APP_URL } = process.env;
let Api = "";

if (process.env.NODE_ENV === "production") {
  Api = `${REACT_APP_PRODURL}principal`;
} else {
  Api = `${REACT_APP_URL}principal`;
}

export const getActiveClientsAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetActiveClients/` + id;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getActiveCreditsAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetActiveCredits/` + id;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getVecidoCreditsAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetVencidoCredits/` + id;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getTotalCobradoAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetTotalCobrado/` + id;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getChartDiaAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetChartDia/` + id;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getCreditsPendienteAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetNoDesembolsadoCredits/` + id;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getMoraByGestorAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetMora/` + id;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getMoraTotalAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetMoraTotal/` + id;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getClientbyLcationAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetClientsByClocation/` + id;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getCreditsByPeriodicidadAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetClientsByPeriodicidad/` + id;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};

export const getColocacionSemanalAsync = async (token, id) => {
  const result = { statusResponse: true, data: [], error: null };
  let service = `${Api}/GetColocacionSemanal/` + id;
  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  try {
    await authAxios.get(service).then((resp) => {
      if (resp.status !== 200) {
        result.statusResponse = false;
        result.error = resp.title;
      } else {
        result.statusResponse = true;
        result.data = resp.data;
      }
    });
  } catch (error) {
    result.statusResponse = false;
    result.error = errorResponse(error.response);
  }
  return result;
};
