import React, { useState, useContext, useEffect } from 'react';
import {
	TextField,
	Typography,
	Container,
	Stack,
	Button,
	Paper,
	InputAdornment,
	Divider,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material';
import { DataContext } from '../../../../context/DataContext';
import { isAccess, simpleMessage } from '../../../../helpers/Helpers';
import { getToken } from '../../../../api/Account';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import {
	addCreditNoteAsync,
	addQuoteAsync,
	getQuoteSugestAsync,
	getSaldoAnteriorAsync,
	liquidateCreditAsync
} from '../../../../api/Credits';

import Swal from 'sweetalert2';

const AddAbono = ({ setShowModal, id, localReload, setLocalReload, isNominal }) => {
	const [montoAbono, setMontoAbono] = useState('');
	const [quoteSugest, setquoteSugest] = useState(0);
	const [totalAbonado, setTotalAbonado] = useState(0);
	const [nuevoSaldo, setNuevoSaldo] = useState(0);
	const [isLiquidate, setIsLiquidate] = useState(false);
	const [isDisable, setIsDisable] = useState(false);

	const [tipoAbono, setTipoAbono] = useState(1);

	const token = getToken();
	const { setIsLoading, setIsUnautorized, access, setShowPrintBillModal, setDataAbono } =
		useContext(DataContext);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const result = await getQuoteSugestAsync(token, id);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);
			setquoteSugest(result.data.result);
			getSaldoAnterior();
		})();
	}, []);

	//Devuelve un entero positivo
	const funtionSetMontoCuota = (value) => {
		if (/^\d*\.?\d*$/.test(value.toString())) {
			if (value > totalAbonado) {
				simpleMessage('No puede abonar mas de lo que debe', 'error');
				setMontoAbono(totalAbonado);
				setNuevoSaldo(0);
				return;
			}
			setMontoAbono(value);
			setNuevoSaldo(parseFloat(totalAbonado) - parseFloat(value === '' ? 0 : value));
			return;
		}
	};

	const addCuota = async () => {
		if (montoAbono.length === 0) {
			simpleMessage('Debe ingresar un monto a abonar', 'error');
			return;
		}

		if (nuevoSaldo < 0) {
			simpleMessage('No puede ingresar una cuota mayor al saldo del credito', 'error');
			return;
		}
		setIsLoading(true);
		const data = {
			creditId: id,
			totalRecibido: montoAbono,
			concepto:
				tipoAbono === 1
					? 'ABONO DEL DIA'
					: tipoAbono === 2
					? 'ABONO'
					: tipoAbono === 3
					? 'NOTA DE CREDITO'
					: 'NOTA DE CREDITO POR MONTO TOTAL',
			moneda: 1
		};

		setIsLoading(true);

		if (isLiquidate) {
			const result = await liquidateCreditAsync(token, data);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
      simpleMessage('Nota de Crédito Aplicada...!', 'success');
		} else {
			const result =
				tipoAbono === 3
					? await addCreditNoteAsync(token, data)
					: await addQuoteAsync(token, data);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			if (tipoAbono !== 3) {
				printBill(result.data.result);
			} else {
				simpleMessage('Nota de Crédito Aplicada...!', 'success');
			}
		}

		setIsLoading(false);

		setLocalReload(!localReload);
		setShowModal(false);
	};

	const getSaldoAnterior = async () => {
		const result = await getSaldoAnteriorAsync(token, id);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setTotalAbonado(result.data.result);
	};

	const onChangeTipoAbono = (tipo) => {
		setTipoAbono(tipo);

		if (tipo === 4) {
			setIsDisable(true);
			setIsLiquidate(true);
			setMontoAbono(totalAbonado);
		} else {
			setIsDisable(false);
			setIsLiquidate(false);
			setMontoAbono('');
		}
	};

	const printBill = (billData) => {
		Swal.fire({
			title: 'Cuota Aplicada...!',
			text: '¿Deseas imprimir el recibo?',
			icon: 'question',
			showCancelButton: true,

			confirmButtonColor: '#2979ff',
			cancelButtonColor: '#f50057',

			confirmButtonText: 'Imprimir!',
			cancelButtonText: 'Cancelar',
			customClass: {
				popup: 'border'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				setDataAbono(billData);
				setShowPrintBillModal(true);
			}
		});
	};

	return (
		<div>
			<Container style={{ width: 550 }}>
				<Stack direction="column" spacing={1}>
					<Paper
						style={{
							padding: 20
						}}
					>
						<Stack direction="column" spacing={2} justifyContent={'center'}>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-around"
								divider={<Divider orientation="vertical" flexItem />}
							>
								<Stack spacing={1} direction="row" justifyContent={'center'}>
									<Typography
										fontWeight={'bold'}
										style={{
											color: '#2979ff'
										}}
									>
										CREDITO # :
									</Typography>
									<Typography
										style={{
											fontWeight: 'bold'
										}}
									>
										{id}
									</Typography>
								</Stack>

								<Stack spacing={1} direction="row" justifyContent={'center'}>
									<Typography
										fontWeight={'bold'}
										style={{
											color: '#00a152'
										}}
									>
										Ponerse Al Día :
									</Typography>
									<Typography
										style={{
											fontWeight: 'bold'
										}}
									>
										{`${new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(quoteSugest)}`}
									</Typography>
								</Stack>
							</Stack>

							<Divider />

							<TextField
								required
								fullWidth
								disabled={isDisable}
								onChange={(e) => funtionSetMontoCuota(e.target.value)}
								value={montoAbono}
								label={'Monto a abonar'}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">C$</InputAdornment>
									)
								}}
							/>

							<FormControl fullWidth style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">
									Tipo de abono
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => onChangeTipoAbono(e.target.value)}
									value={tipoAbono}
									label="Tipo de Abono"
								>
									<MenuItem value={1}>ABONO DEL DIA</MenuItem>
									<MenuItem value={2}>ABONO</MenuItem>
									{isAccess(access, 'CREDITNOTE') ? (
										<MenuItem value={3}>NOTA DE CREDITO</MenuItem>
									) : (
										<></>
									)}
									{isAccess(access, 'CREDIT LIQUIDATE') ? (
										<MenuItem value={4}>NOTA DE CREDITO MONTO TOTAL</MenuItem>
									) : (
										<></>
									)}
								</Select>
							</FormControl>

							{/* {isAccess(access, "CREDIT LIQUIDATE") ? (
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems="center"
                  spacing={1}
                >
                  <FormControlLabel
                    disabled={isDisable}
                    labelPlacement="end"
                    style={{ color: "#ffc400", fontWeight: "bold" }}
                    control={
                      <Checkbox
                        checked={isLiquidate}
                        onChange={() => setIsLiquidate(!isLiquidate)}
                        sx={{
                          color: "#ffc400",
                          "&.Mui-checked": {
                            color: "#ffc400",
                          },
                        }}
                      />
                    }
                    label="Abonar y Liquidar"
                  />
                </Stack>
              ) : (
                <></>
              )} */}

							{/* <TextField
                required
                fullWidth
                onChange={(e) => setConcepto(e.target.value.toUpperCase())}
                value={concepto}
                label={"Concepto de pago"}
              /> */}

							<Button
								variant="outlined"
								startIcon={
									<FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
								}
								fullWidth
								onClick={() => addCuota()}
								style={{
									color: '#00a152',
									borderColor: '#00a152'
								}}
								size="large"
							>
								Agregar Cuota
							</Button>
						</Stack>
					</Paper>

					<Paper
						style={{
							padding: 10
						}}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-around"
							divider={<Divider orientation="vertical" flexItem />}
						>
							<Stack spacing={1} justifyContent={'center'}>
								<Typography
									fontWeight={'bold'}
									textAlign="center"
									style={{
										color: '#00a152'
									}}
								>
									Saldo Anterior:
								</Typography>
								<Typography
									textAlign="center"
									style={{
										fontWeight: 'bold'
									}}
								>
									{`${new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(totalAbonado)}`}
								</Typography>
							</Stack>

							<Stack spacing={1} justifyContent={'center'}>
								<Typography
									textAlign="center"
									fontWeight={'bold'}
									style={{
										color: '#f50057'
									}}
								>
									Nuevo Saldo:
								</Typography>
								<Typography
									textAlign="center"
									style={{
										fontWeight: 'bold'
									}}
								>
									{`${new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(nuevoSaldo)}`}
								</Typography>
							</Stack>
						</Stack>
					</Paper>
				</Stack>
			</Container>
		</div>
	);
};

export default AddAbono;
