import React, { useState } from "react";
import { Paper, Box, Tabs, Tab, Divider, Container } from "@mui/material";

import PropTypes from "prop-types";

import LibraryBooksRoundedIcon from "@mui/icons-material/LibraryBooksRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import CatalogoCuentas from "./cuentasContable/CatalogoCuentas";
import LibroDiario from "./LibroDiario";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Contabilidad = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Container maxWidth="lg">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="icon label tabs example"
            centered
          >
            <Tab
              icon={<LibraryBooksRoundedIcon style={{ fontSize: 30 }} />}
              label="Catalogo de Cuentas"
              {...a11yProps(0)}
              style={{ fontSize: 12 }}
            />

            <Tab
              icon={<MenuBookRoundedIcon style={{ fontSize: 30 }} />}
              label="Libro Diario"
              {...a11yProps(0)}
              style={{ fontSize: 12 }}
            />
          </Tabs>

          <Divider style={{ marginTop: 10 }} />
          <TabPanel value={value} index={0}>
            <CatalogoCuentas />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LibroDiario />
          </TabPanel>
        </Paper>
      </Container>
    </div>
  );
};

export default Contabilidad;
