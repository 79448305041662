import React, { useContext, useState } from 'react';
import {
	Paper,
	Box,
	Tabs,
	Tab,
	Divider,
	Typography,
	Container,
	Stack,
	Button
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronCircleLeft,
	faCreditCard,
	faPeopleArrows,
	faUser
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { DataContext } from '../../../context/DataContext';
import { useNavigate, useParams } from 'react-router-dom';

import { getRuta } from '../../../helpers/Helpers';
import ClientDetails from './ClientDetails';
import ClientReferences from './ClientReferences';
import ClientCredits from './ClientCredits';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

export const DetalleClientContainer = () => {
	const { access } = useContext(DataContext);
	let ruta = getRuta();
	let navigate = useNavigate();
	const { id } = useParams();
	const [value, setValue] = useState(0);
	const [clientName, setClientName] = useState('');
	const [promedio, setPromedio] = useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					<Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={1}>
						<Button
							onClick={() => {
								navigate(`${ruta}/clients`);
							}}
							style={{
								color: '#2979ff',
								borderColor: '#2979ff'
							}}
							variant="outlined"
						>
							<FontAwesomeIcon
								style={{ marginRight: 10, fontSize: 20 }}
								icon={faChevronCircleLeft}
							/>
							Regresar
						</Button>

						<Stack direction={'row'} spacing={1} style={{ flexGrow: 1 }}>
							<Typography
								color={'#00a152'}
								variant="h6"
								fontWeight={'bold'}
								className="d-none d-sm-block"
							>
								Cliente:
							</Typography>
							<Typography variant="h6" fontWeight={'bold'}>
								{clientName}
							</Typography>
						</Stack>

						<Typography color={'#ffc400'} className="d-none d-sm-block">
							{`Estadistica Global: ${promedio}`}
						</Typography>
					</Stack>

					<Divider style={{ marginTop: 10 }} />

					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="icon label tabs example"
						centered
					>
						<Tab
							icon={<FontAwesomeIcon icon={faUser} style={{ fontSize: 20 }} />}
							label="Cliente"
							{...a11yProps(0)}
							style={{ fontSize: 12 }}
						/>

						<Tab
							icon={
								<FontAwesomeIcon icon={faPeopleArrows} style={{ fontSize: 20 }} />
							}
							label="Referencias"
							{...a11yProps(0)}
							style={{ fontSize: 12 }}
						/>

						<Tab
							icon={<FontAwesomeIcon icon={faCreditCard} style={{ fontSize: 20 }} />}
							label="Creditos"
							{...a11yProps(0)}
							style={{ fontSize: 12 }}
						/>
					</Tabs>

					<Divider style={{ marginTop: 10 }} />

					<TabPanel value={value} index={0}>
						<ClientDetails
							id={id}
							setClientName={setClientName}
							setPromedio={setPromedio}
						/>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<ClientReferences id={id} />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<ClientCredits id={id} />
					</TabPanel>
				</Paper>
			</Container>
		</div>
	);
};
